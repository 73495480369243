import React, { useState } from 'react';

const OTPInput = ({ length = 6, onSubmit, onCancel }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));

    const handleChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        const newOtp = [...otp];
        newOtp[index] = value.substring(0, 1);
        setOtp(newOtp);

        if (value && index < length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            if (otp[index] === '') {
                if (index > 0) {
                    document.getElementById(`otp-input-${index - 1}`).focus();
                }
            } else {
                newOtp[index] = '';
                setOtp(newOtp);
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('Text').replace(/[^0-9]/g, '');
        if (pasteData.length === length) {
            const newOtp = pasteData.split('').slice(0, length);
            setOtp(newOtp);
            document.getElementById(`otp-input-${length - 1}`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(otp.join(''));
    };

    return (
        <div className="otp-overlay">
            <form className="otp-container" onSubmit={handleSubmit}>
                <h2 style={{ color: '#2e7d32', marginBottom: '10px' }}>Email Verification</h2>
                <p style={{ color: '#4caf50', fontSize: '14px', marginBottom: '20px' }}>
                    Enter the 6-digit verification code that was sent to your email address.
                </p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            id={`otp-input-${index}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleBackspace(e, index)}
                            onPaste={handlePaste}
                            className="otp-input"
                        />
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                    <button type="button" className="otp-cancel" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="submit" className="otp-submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OTPInput;
