import styled from "styled-components";

const StyledWalletSwitchContainer = styled.div`     
    height: 2.7rem;
    background-color: #2E2E2EA6;
    border-radius: 100px;  
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(10px);

    @media (width <= 500px){
        width: 90vw;
        height: 2.7rem !important;
        font-size: 12px;
    }
`;

const StyledWalletOption = styled.div`
    width: 120px;
    height: 2.7rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;  
    border-width: 1px;
    border-color: ${({isSelected})=>isSelected ? '#FFFFFF' : 'transparent'};
    border-style: solid;
    padding: 10px;
    color: ${({isSelected})=>isSelected ? '#FFFFFF' : '#A6A6A6'};
    font-weight: 500;
    font-size: 18px;

    @media (width <= 500px){
        flex: 1;
        height: 2.7rem !important;
        padding: 5px;
        font-size: 16px;
    }
`;

export default function WalletSelector({selectedWallet = 'metamask', onWalletChange, ...props}){
    return (
        <>
            <StyledWalletSwitchContainer {...props}>
                <StyledWalletOption onClick={()=>onWalletChange('IMX')} isSelected={selectedWallet === 'IMX'}>IMX</StyledWalletOption>
                <StyledWalletOption onClick={()=>onWalletChange('ZkEvm')} isSelected={selectedWallet === 'ZkEvm'}>ZkEvm</StyledWalletOption>                
            </StyledWalletSwitchContainer>
        </>
    );
}