import React from 'react';

const NftCard = ({item, goTo}) => {
    return (
        <div>
            <div className='nft-bg' style={{backgroundImage:`url(${item.ImageUrl})`}} onClick={()=>goTo(item.Address)}>
                {/* <div className='tag'>{item.status}</div> */}
            </div>
            <div className='name-container' onClick={()=>goTo(item.Address)}>
                <img alt='small-logo' src='/assets/img/Green_Isotype.png'/>
                <h4>{item.Name} #{item.metadata.symbol}</h4>
            </div>
            <div className='bottom-container'>
                <div>
                    <span className='bid'>Price</span> <br/><span className='price'>{item.price}</span> <span className='sol'>SOL</span>
                </div>
                <div>
                    <button className='greenBtn' onClick={()=>goTo(item.Address)}>Buy Now</button>
                </div>
            </div>
        </div>
    );
};

export default NftCard;