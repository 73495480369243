/* eslint-disable no-lone-blocks */
import React, { useContext } from "react";
import { MenuNoLogin } from "./MenuNoLogin";
import { Context } from "../../context/WalletContext";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { passportInstance } from "../../context/PassportService";

function NavBar() {
  const {
    state,
    logOut,
  } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate('/profile')
  };
  const toggleNavbar = () => {
    // menu mobile not working on ios. this code is to make it work
    const jQuery = window.jQuery
    if (jQuery('#navbarSupportedContent').hasClass('open')) {
      jQuery('#navbarSupportedContent').addClass('close')
      jQuery('#navbarSupportedContent').removeClass('open')
    } else {
      jQuery('#navbarSupportedContent').addClass('open')
      jQuery('#navbarSupportedContent').removeClass('close')
    }
  };

  const logoutService = async () => {
    const currentSession = localStorage.getItem("userInfo")
    const infoFromString = JSON.parse(currentSession);
    localStorage.removeItem("userInfo")
    try {
      if (infoFromString.authType === "ImmutablePassport") {
        await passportInstance.logout();
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <header
      id="header"
      style={
        location.pathname === "/signup" ||
          location.pathname === "/" ||
          location.pathname === "/download"
          ? { height: 75 }
          : null
      }
    >
      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand-lg"
      >
        <div className="container-fluid container header" style={{ width: "50%" }}>
          <Link className="navbar-brand" to="/">
            {location.pathname === "/signup" ||
              location.pathname === "/" ||
              location.pathname === "/download" ? (
              <img
                src="/assets/img/White_Full_Logo.png"
                alt="sticky brand-logo"
                width={100}
              />
            ) : (
              <img
                className="navbar-brand-sticky"
                src="/assets/img/logo.png"
                alt="sticky brand-logo"
              />
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>
        <div className="container-fluid container">
          <div
            id="navbarSupportedContent"
            className="close navbar-collapse"
          >
            <ul className="navbar-nav items mx-auto">
              <MenuNoLogin itemLocation={'navbar'} />
            </ul>
            <div className="button-top-container">
              {state.user === null ? (
                <button className="btnTransparent" onClick={handleLoginClick}>
                  Login
                </button>
              ) : (
                <button
                  className="btnTransparent"
                  onClick={() => {
                    logOut();
                    logoutService();
                  }}
                >
                  Logout
                </button>
              )}
              <div style={{ width: 10 }} />
              <button
                id="address"
                className="greenBtn cut-button"
                onClick={() => navigate("/profile")}
              >
                {
                  state.userWallet
                    ? "Profile" : "Connect Wallet"
                }
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
