import styled from "styled-components";
import { createPortal } from 'react-dom';
import { forwardRef, useImperativeHandle, useRef } from "react";

const StyledChangeWalletModal = styled.dialog`
    width: 600px;
    height: 300px;
    border-radius: 14px;
    background: #1C1C1C05;
    backdrop-filter: blur(1.5rem);
    border-width: 0px;
    border-style: solid;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    &[open]{
        display: flex;
    }

    .title {
        color: #FFFFFF;
        font-size: 32px;
        font-weight: 700;
        line-height: 26.4px;
    }

    .content {        
        font-size: 22px;
        font-weight: 500;
        line-height: 26.4px;
        text-align: center;
    }

    .actions-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
        padding: 0px 50px 0px 50px;        
    }

    .action-button{
        width: 35%;
        height: 50px;
        border-radius: 14px;
        border-style: solid;
        font-size: 18px;
        font-weight: 500;
    }

    .confirm-button {
        background: linear-gradient(#38EF7D, #11998E);
        border-width: 0px;
        color: #FFFFFF;
    }

    .cancel-button{
        border-color: #38EF7D;
        border-width: 1px;
        background: transparent;
        color: #38EF7D;
    }
    
    .spacer {
        display: flex;
        flex: 2;
    }
`;


const changeWalletModal = forwardRef(function ChangeWalletModal({onConfirm, title, description}, ref){

        const dialog = useRef();

        useImperativeHandle(ref, () => {
            return {
                open: open,
                close: close,
            }
        });

        function open() {
            dialog.current.showModal();
        }

        function close(){
            dialog.current.close();
        }

        return createPortal(        
            <StyledChangeWalletModal ref={dialog}>
                <p className="title">{title}</p>
                <p className="content">{description}</p>
                <div className="actions-container">
                    <button className="action-button cancel-button" onClick={()=>{dialog.current.close();}}>{onConfirm ? 'Cancel' : 'OK'}</button>
                    {
                        onConfirm &&
                        <>
                        <div className="spacer"></div>
                        <button className="action-button confirm-button" onClick={onConfirm}>Yes</button>
                        </>
                    }
                </div>
            </StyledChangeWalletModal>,
            document.getElementById('modal')
        );
    },
    document.getElementById('modal')
    );

export default changeWalletModal;