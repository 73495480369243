import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import PassportLogo from "../assets/img/passport-logo.png";
import LoaderGlass from "../assets/svg/LoaderGlass";
import OpenLootLogo2 from "../assets/img/OpenLootLogo.png";
import { Ethereum, ImmutablePassport, Toast } from "../utils/Global";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import qorbi_coin from "../assets/img/mini_qorbi.png";
import OTPInput from "../components/OTP";
import Popup from "../components/Popup";
import InputWallet from "../components/InputWallet";
import SendEmberPopup from "../components/EmberPopup";
import { Context } from "../context/WalletContext";
import { passportInstance } from "../context/PassportService";
import styled from "styled-components";
import ErrorShowing from "../components/ErrorShowing";
import { useLocation } from "react-router";
import WalletManagerModal from "../components/Modals/WalletManagerDialog";

const qorbiServices = require("../context/QorbiService");
const immutableServices = require("../context/ImmutableService");

const StyledProfilePage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 10vh;
  gap: 35px;

  .left-side-profile,
  .right-side-profile {
    display: flex;
    width: 600px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .profile-main-rectangle {
    width: 600px;
    height: 93px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    border-width: 0px;
    padding: 24px;
    background: #1c1c1c;
    border-style: solid;
  }

  .profile-username {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    margin: 0px;
  }

  .wallet-level-container {
    flex: 2;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .manage-wallets-container {
    width: 150px;
    height: 30px;
    border-radius: 14px;
    padding: 10px;
    background: linear-gradient(#38ef7d, #11998e);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 16px;
    font-family: Exo 2;
    font-weight: 500;
    font-size: 14px;
    leading-trim: Cap height;
    line-height: 100%;
    letter-spacing: 0%;
  }

  .manage-wallets-text {
    margin: 0px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    leading-trim: Cap height;
    line-height: 100%;
    letter-spacing: 0%;
  }

  .user-level-container {
    width: 80px;
    height: 30px;
    border-radius: 16px;
    border-width: 0.4px;
    border-color: #38ef7d;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    leading-trim: Cap height;
    line-height: 100%;
    letter-spacing: 0%;
    border-style: solid;
    color: #ffffff;
  }

  .profile-secondary-rectangle {
    width: 600px;
    height: 284px;
    border-radius: 24px;
    border-width: 0.4px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    background: #1c1c1c;
    margin-top: 32px;
  }

  .profile-info-row {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .profile-info-key {
    font-family: Exo 2;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #ffffff;
  }

  .profile-info-value {
    font-family: Exo 2;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: right;
    color: #38ef7d;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .copy-icon {
    margin-left: 16px;
    cursor: pointer;
  }

  .profile-tournaments-title {
    width: 100%;
    height: 58px;
    justify-content: space-between;
    border-radius: 21px;
    border-width: 0.4px;
    padding: 16px;
    background: #1c1c1c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0%;
    margin-bottom: 32px;
  }

  #hot-news-container {
    width: 100%;
    gap: 24px;
    border-radius: 24px;
    border-width: 0.4px;
    padding: 24px;
    background: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .hot-new-tile {
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-date-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  .go-button {
    width: 70px;
    height: 30px;
    border-radius: 16px;
    border-width: 0.4px;
    padding: 10px;
    background: #323232;
    border-color: #38ef7d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    leading-trim: Cap height;
    line-height: 100%;
    letter-spacing: 0%;
    color: #ffffff;
    cursor: pointer;
  }

  .new-title {
    font-family: Exo 2;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #ffffff;
  }

  .new-date {
    font-family: Exo 2;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #38ef7d;
  }

  #empty-news-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
  }

  .qorbi-balance-container {
    margin-top: 32px;
    width: 100%;
    height: 225px;
    border-radius: 24px;
    border-width: 0.4px;
    padding: 24px;
    background: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .balance-title {
    font-family: Exo 2;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    margin: 0px;
  }

  .balance-value {
    font-family: Exo;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    color: #38ef7d;
    margin: 0px;
  }

  #withdraw-button {
    width: 95%;
    height: 51px;
    gap: 10px;
    border-radius: 14px;
    padding: 16px;
    background: linear-gradient(#38ef7d, #11998e);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }

  @media (width <= 800px) {
    flex-direction: column;
    margin-top: 25%;
    height: 100%;
    padding: 0px 10px 0px 10px;

    .left-side-profile,
    .right-side-profile {
      width: 100%;
    }

    .profile-main-rectangle {
      width: 100%;
    }

    .profile-secondary-rectangle {
      width: 100%;
    }
  }
`;

const UserProfile = () => {
  const storedItem = localStorage.getItem("unauthorizedPath");
  const { metamaskLogin, localLogin, state } = useContext(Context);
  const {
    setRegisterWallet,
    setRegisterUser,
    setQorbiUserData,
    qorbiUserData,
  } = useSphereOneProvider();
  const [loading, setLoading] = useState(true);
  const [migrationLoader, setMigrationLoader] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState();
  const [isEmberPopupOpen, setEmberPopupOpen] = useState(false);
  const [hotNews, setHotNews] = useState([]);
  const [walletInput, setWalletInput] = useState(false);
  const [isQorbOwner, setIsQorbOwner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pendingMigrations, setPendingMigrations] = useState({
    total: 0,
    completed: 0,
  });
  const manageWalletModal = useRef();

  const location = useLocation();
  const isLoggedIn = state.user !== null;

  // const redirect_uri = "http://localhost:3000/profile"
  const redirect_uri = "https://qorbiworld.com/profile";

  const getNews = async () => {
    // const baseUrl = "http://localhost:4001"
    const location = window.location;
    const baseUrl =
      location.host === "dev.qorbaby.com"
        ? "https://dev-api.qorbiworld.com"
        : "https://api.qorbiworld.com";
    const hotNewsResponse = await axios.get(`${baseUrl}/v1/hotnews`);
    setHotNews(hotNewsResponse.data);
  };

  const openEmberPopup = () => {
    setEmberPopupOpen(true);
  };

  const closeEmberPopup = () => {
    setEmberPopupOpen(false);
  };

  const handleSuccessEmberTransfer = (transferredAmount) => {
    setQorbiUserData((prevData) => ({
      ...prevData,
      emberBalance: prevData.emberBalance - transferredAmount,
    }));
    Toast.set({
      show: true,
      desc: "Success! You added funds for the Qorb Pool!",
      type: "success",
    });
  };

  const getQorbOwner = async () => {
    const response = await immutableServices.assetOwner(
      state?.user?.data,
      "qorb"
    );
    setIsQorbOwner(response);
  };

  useEffect(() => {
    getQorbOwner();
    getNews();
    try {
      passportInstance.loginCallback();
    } catch (error) {
      console.error(error);
    }
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const openLootSource = localStorage.getItem("OpenLootSource");

    const fetchAndSetOpenLootInfo = async (source) => {
      localStorage.removeItem("OpenLootSource");
      if (source === "connect" && !qorbiUserData.openLootInfo) {
        const openLootData = await qorbiServices.updateOpenLootUserData(
          code,
          qorbiUserData._id,
          redirect_uri
        );
        setQorbiUserData((prevData) => ({
          ...prevData,
          openLootInfo: openLootData,
        }));
      } else if (source === "auth") {
        const openLootData = await qorbiServices.getOpenLootUserData(
          code,
          qorbiUserData._id,
          redirect_uri
        );
        setMigrationLoader(true);
        while (true) {
          const canMigrate = await qorbiServices.availableOpenlootMigration();
          if (canMigrate.available) {
            const trackId = await qorbiServices.migrateAssetsFromOpenLoot(
              openLootData.sessionsToken.accessToken,
              state.user.data
            );
            try {
              while (true) {
                const migrationStatus = await qorbiServices.trackMigration(
                  trackId.followId
                );
                const completedCount = migrationStatus.filter(
                  (item) => item.finished
                ).length;
                setPendingMigrations({
                  completed: completedCount,
                  total: migrationStatus.length,
                });
                const allFinished = migrationStatus.every(
                  (item) => item.finished
                );
                if (allFinished) {
                  const allSuccessful = migrationStatus.every(
                    (item) => item.success
                  );
                  if (!allSuccessful) {
                    setMigrationLoader(false);
                    Toast.set({
                      show: true,
                      desc: "Oops! Some migrations failed. Please contact us using discord. CODE: OL-ZKEVM",
                      type: "error",
                    });
                  } else {
                    setMigrationLoader(false);
                    Toast.set({
                      show: true,
                      desc: "All migrations completed successfully!",
                      type: "success",
                    });
                  }
                  break;
                }
                await new Promise((resolve) => setTimeout(resolve, 3000));
              }
            } catch (error) {
              console.error("Error tracking migration:", error);
              throw error;
            }
            break;
          }
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
      }
    };
    if (qorbiUserData) {
      setLoading(false);
      if (code) {
        fetchAndSetOpenLootInfo(openLootSource);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qorbiUserData]);

  const handleOtpSubmit = async (otpCode) => {
    setShowOTP(false);
    setOTP(otpCode);
    setWalletInput(true);
  };

  const sendFunds = async (address) => {
    try {
      Toast.set({
        show: true,
        desc: "We are starting the withdraw, wait for a second!",
        type: "success",
      });
      setWalletInput(false);
      await qorbiServices.withdrawMana(address, OTP);
      Toast.set({
        show: true,
        desc: "Success! You have withdrew the $QORBI balance from your Mana Questing Account!",
        type: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseOtpSubmit = () => {
    setShowOTP(false);
  };

  const isIMX = async (address) => {
    try {
      const immutableResponse = await axios.get(
        `https://api.x.immutable.com/v1/users/${address}`
      );
      return !!immutableResponse.data;
    } catch (immutableError) {
      return false;
    }
  };

  const validateWallet = async (address) => {
    const isImx = await isIMX(address);
    if (isImx) {
      try {
        const qorbiUser = await qorbiServices.userByToken();
        if (
          !qorbiUser.email ||
          qorbiUser.username.startsWith("not-username-")
        ) {
          setRegisterUser(true);
        }
        setQorbiUserData(qorbiUser);
      } catch (error) {
        console.error(error);
      }
    } else {
      setRegisterWallet({ show: true, wallet: address });
    }
  };

  const connect_Passport = async () => {
    try {
      setLoading(true);
      const provider = passportInstance.connectEvm();
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      const userInfo = {
        authType: ImmutablePassport,
        data: accounts[0],
        isWombat: undefined,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      try {
        const userResponse = await qorbiServices.userByToken();
        setQorbiUserData(userResponse);
      } catch (error) {
        setRegisterUser(true);
      }
      localLogin(userInfo);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  };

  const connect_eth_provider = async () => {
    try {
      if ("ethereum" in window) {
        const userAddress = await metamaskLogin();
        if (userAddress.data) {
          const userInfo = {
            authType: Ethereum,
            data: userAddress.data,
            isWombat: window.ethereum.isWombat,
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          validateWallet(userInfo.data);
          localLogin(userInfo);
          if (storedItem) {
            localStorage.removeItem("unauthorizedPath");
            window.location.href = storedItem;
          }
        }
      } else {
        Toast.set({
          show: true,
          desc: (
            <p>
              Metamask is not installed. <br />
              <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                Metamask
              </a>
            </p>
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectOpenLoot = async (source) => {
    localStorage.setItem("OpenLootSource", source);
    const clientId = "f490040a-9d54-4611-819c-eda21281e5e1";
    // const clientId = "695f4869-6fa2-4c7e-a77c-1c3704a26b59";
    const responseType = "code";
    const authUrl = `https://api.openloot.com/oauth/v1/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirect_uri
    )}&response_type=${responseType}`;
    window.location.href = authUrl;
  };

  const handleWithdraw = async () => {
    if (!qorbiUserData.on3Account?.balance) {
      setShowPopup({
        title: "Not Enough QORBI!",
        content:
          "You need at least 7,000 $QORBI to withdraw—keep grinding, gamer!",
      });
      return;
    }
    if (qorbiUserData.on3Account.balance["QORBI"] < 7000) {
      setShowPopup({
        title: "Not Enough QORBI!",
        content:
          "You need at least 7,000 $QORBI to withdraw—keep grinding, gamer!",
      });
      return;
    } else {
      qorbiServices.requestOtp();
      setShowOTP(true);
    }
  };

  if (loading || !isLoggedIn) {
    return (
      <div className="loading-magnifying-glass">
        <LoaderGlass></LoaderGlass>
        <h1 className="banner-title">
          Getting Information from the librarian!!!
        </h1>
        <h2 className="banner-title">Yes, we have a librarian...</h2>
        <div className="signin-overlay">
          <div className="signin-box">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1>SIGN IN</h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="welcome">
                <p style={{ marginRight: 3 }}>Welcome to</p>
                <p style={{ color: "green" }}>Qorbi World</p>
              </div>
            </div>
            <p className="welcome">Login using Gmail-Email-Apple</p>
            <div className="signin-buttons">
              <button
                onClick={connect_Passport}
                style={{ backgroundColor: "#14b3cb" }}
              >
                <img
                  src={PassportLogo}
                  alt="Passport Logo"
                  width={25}
                  height={25}
                />{" "}
                Login with Passport
              </button>
            </div>
            <p className="welcome">Or connect with your wallet</p>
            <div className="signin-buttons">
              <button
                onClick={connect_eth_provider}
                style={{ backgroundColor: "#eb8022" }}
              >
                <MetamaskLogo /> Connect with Metamask
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <StyledProfilePage>
      {/* {toastMsg && <ErrorShowing errorText={toastMsg} />} */}
      <WalletManagerModal ref={manageWalletModal}></WalletManagerModal>
      {walletInput && <InputWallet onSuccess={sendFunds} />}
      {showPopup && (
        <Popup
          onClose={() => setShowPopup(false)}
          title={showPopup.title}
          content={
            <>
              <p>{showPopup.content}</p>
            </>
          }
        />
      )}
      <div className="left-side-profile">
        <div className="profile-main-rectangle">
          <div className="user-avatar"></div>
          <p className="profile-username">{qorbiUserData?.username}</p>
          <div className="wallet-level-container">
            <div
              className="manage-wallets-container"
              onClick={() => manageWalletModal.current.open()}
            >
              <p className="manage-wallets-text">Manage Wallets</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e3e3e3"
              >
                <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
              </svg>
            </div>
            <div className="user-level-container">
              Level: {qorbiUserData?.rank?.level}
            </div>
          </div>
        </div>

        <div className="qorbi-balance-container">
          <p className="balance-title">Current Balance</p>
          <p className="balance-value">
            {qorbiUserData?.on3Account?.balance?.["QORBI"] || 0}
          </p>
          <div id="withdraw-button" onClick={handleWithdraw}>
            Withdraw
          </div>
        </div>

        <div className="profile-secondary-rectangle">
          {/* <div className="profile-info-row">
            Balance: {qorbiUserData?.on3Account?.balance?.["QORBI"] || 0} QORBI
            | {qorbiUserData.emberBalance || 0} EMBER
          </div> */}
          <div className="profile-info-row">
            <p className="profile-info-key">Session Wallet:</p>
            <p className="profile-info-value">
              {state?.user?.data.substring(0, state?.user?.data.length - 15)}...
              <div
                className="copy-icon"
                onClick={function () {
                  return navigator.clipboard.writeText(state?.user?.data);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 15C9 12.1716 9 10.7574 9.87868 9.87868C10.7574 9 12.1716 9 15 9H16C18.8284 9 20.2426 9 21.1213 9.87868C22 10.7574 22 12.1716 22 15V16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15C12.1716 22 10.7574 22 9.87868 21.1213C9 20.2426 9 18.8284 9 16V15Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.9999 9C16.9975 6.04291 16.9528 4.51121 16.092 3.46243C15.9258 3.25989 15.7401 3.07418 15.5376 2.90796C14.4312 2 12.7875 2 9.5 2C6.21252 2 4.56878 2 3.46243 2.90796C3.25989 3.07417 3.07418 3.25989 2.90796 3.46243C2 4.56878 2 6.21252 2 9.5C2 12.7875 2 14.4312 2.90796 15.5376C3.07417 15.7401 3.25989 15.9258 3.46243 16.092C4.51121 16.9528 6.04291 16.9975 9 16.9999"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </p>
          </div>
          <div className="profile-info-row">
            <p className="profile-info-key">Winrate:</p>
            <p className="profile-info-value">
              {qorbiUserData?.winrate || "N/A"}
            </p>
          </div>
          <div className="profile-info-row">
            <p className="profile-info-key">Score:</p>
            <p className="profile-info-value">
              {qorbiUserData?.rank.totalScore}
            </p>
          </div>

          <div className="profile-info-row">
            <p className="profile-info-key">Referral Code:</p>
            <p className="profile-info-value">
              {qorbiUserData?.referalCode || "Generating"}
              <div
                className="copy-icon"
                onClick={function () {
                  return navigator.clipboard.writeText(
                    qorbiUserData?.referalCode
                  );
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 15C9 12.1716 9 10.7574 9.87868 9.87868C10.7574 9 12.1716 9 15 9H16C18.8284 9 20.2426 9 21.1213 9.87868C22 10.7574 22 12.1716 22 15V16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15C12.1716 22 10.7574 22 9.87868 21.1213C9 20.2426 9 18.8284 9 16V15Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.9999 9C16.9975 6.04291 16.9528 4.51121 16.092 3.46243C15.9258 3.25989 15.7401 3.07418 15.5376 2.90796C14.4312 2 12.7875 2 9.5 2C6.21252 2 4.56878 2 3.46243 2.90796C3.25989 3.07417 3.07418 3.25989 2.90796 3.46243C2 4.56878 2 6.21252 2 9.5C2 12.7875 2 14.4312 2.90796 15.5376C3.07417 15.7401 3.25989 15.9258 3.46243 16.092C4.51121 16.9528 6.04291 16.9975 9 16.9999"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </p>
          </div>
        </div>
      </div>
      {showOTP && (
        <OTPInput onSubmit={handleOtpSubmit} onCancel={handleCloseOtpSubmit} />
      )}
      <div className="right-side-profile">
        <div className="profile-tournaments-title">Hot News</div>
        <div id="hot-news-container">
          <div className="hot-new-tile" key={qorbiUserData.dailyEarning}>
            <div className="title-date-container">
              <p className="new-title">
                Daily Earn Limits: {qorbiUserData.dailyEarning} $QORBI
              </p>
            </div>
            <div className="spacer"></div>
            <div
              className="go-button"
              onClick={() =>
                (window.location = "https://qorbiworld.medium.com")
              }
            >
              Go Now
            </div>
          </div>
          {hotNews.length > 0 ? (
            hotNews.map((element) => (
              <div className="hot-new-tile" key={element._id}>
                <div className="title-date-container">
                  <p className="new-title">{element.simpleText}</p>
                </div>
                <div className="spacer"></div>
                <div
                  className="go-button"
                  onClick={() => (window.location = element.link)}
                >
                  Go Now
                </div>
              </div>
            ))
          ) : (
            <div id="empty-news-container">No Urgent News</div>
          )}
        </div>
        {/* <table className="profile-tournaments-table">
          <tbody>
            <tr key={"medium"}>
              <td>Daily Earn Limits: {qorbiUserData.dailyEarning} $QORBI</td>
            </tr>
            <tr key={"medium"}>
              <td>
                Check Our Medium{" "}
                <a
                  style={{ color: "green" }}
                  target="blank"
                  href={"https://qorbiworld.medium.com"}
                >
                  Go Now
                </a>
              </td>
            </tr>
            {hotNews?.length > 0 ? (
              hotNews.map((news) => (
                <tr key={news._id}>
                  <td>
                    {news.simpleText}{" "}
                    {news.link && (
                      <a
                        style={{ color: "green" }}
                        target="blank"
                        href={news.link}
                      >
                        Go Now
                      </a>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="1">No Urgent News</td>
              </tr>
            )}
          </tbody>
        </table> */}
      </div>
    </StyledProfilePage>
  );
};

export default UserProfile;
