import axios from "axios";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/WalletContext";
import { useNavigate } from "react-router";
import GreenIsotype from "../assets/img/Green_Isotype.png";
const qorbiService = require("../context/QorbiService");

const StyledHistoryOfPledging = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
`;

const StyledHistoryPledgingContainer = styled.div`
  width: 600px;
  border-radius: 32px;
  background: #ffffff1a;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;

  .history-pledging-title {
    font-size: 22px;
    line-height: 26.4px;
    color: #ffffff;
    margin-bottom: 24px;
  }

  .history-pledging-header {
    background: #ffffff1a;
    border-radius: 15px;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #ffffff;
  }

  .date-container {
    width: 30%;    
    display: flex;
    justify-content: center;
    align-items: center;    
  }
  .daily-container {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .multiplier-container {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qorbi-container {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .history-pledging-row {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid #FFFFFF1A;

    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #38ef7d;

    .date-value {
      font-size: 16px;
      font-weight: 300;
      line-height: 21.6px;
      color: #ffffff !important;
    }
  }

  @media(width <= 900px){
    width: 90vw;
    margin-top: 20%;
    
    .daily-container {
      width: 27.5%;
    }
    .multiplier-container {
      width: 25%;
    }
    .qorbi-container {
      width: 17.5%;

    }
  }
`;

const StyledLoadingComponent = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  p {
    color: #ffffff;
    margin: 0px;
  }
`;

export default function HistoryOfPledging(){
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    const { state } = useContext(Context);
    const ownerAddress = state.user.data;
    const [qorXTransactions, setQorXTransactions] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(true);    
    const [error, setError] = useState(false);    

    const fetchData = async () => {
        try {
          const data = await qorbiService.getActivePass();
          const passSource = source === "ZkEvm" ? "zkevm" : "immutablex";
          const ownerElement = data.qorXPass.find(
            (element) => element.wallet.toLowerCase() === ownerAddress.toLowerCase()
          );
          if (ownerElement) {
            const response = await qorbiService.getQorXPassDetails(ownerElement.qorXNFTID, passSource);
            setQorXTransactions(response.data);
          } else {
            navigate("/my-assets");
          }
        } catch (error) {
          setError(true);
          console.error("Error fetching QorX data:", error);
        }
      };

      useEffect(()=>{
        async function loadData(){
            await fetchData();
            setisLoading(false);
        }

        loadData();
      }, []);

    return (
        <StyledHistoryOfPledging>
            <StyledHistoryPledgingContainer>
                    <div className="history-pledging-title">
                      History Of Pledging
                    </div>
                    <div className="history-pledging-header">
                      <div className="date-container">Date</div>
                      <div className="daily-container">Daily Qorbi</div>
                      <div className="multiplier-container">Multiplier</div>
                      <div className="qorbi-container">Qorbi</div>
                    </div>
                    {
                        isLoading ?
                        <StyledLoadingComponent>
                            <img src={GreenIsotype}></img>
                            <p>Loading Records...</p>
                        </StyledLoadingComponent>
                        :
                        error ?
                            <StyledLoadingComponent>
                                <img src={GreenIsotype}></img>
                                <p>Something Went Wrong</p>
                            </StyledLoadingComponent>
                            :
                            qorXTransactions?.stakeAsset?.historiesEarn
                            .slice()
                            .map((row) => (
                                <div className="history-pledging-row">
                                  <div className="date-value date-container">{row.day}</div>
                                  <div className="daily-container">{row.dayliQorbi}</div>
                                  <div className="multiplier-container">{row.multiplier}</div>
                                  <div className="qorbi-container">{row.qorbi}</div>
                                </div>
                            ))
                        }
                  </StyledHistoryPledgingContainer>
        </StyledHistoryOfPledging>
    );
}