import styled from "styled-components";
import React from "react";
import { createPortal } from "react-dom";

const StyledFiltersModal = styled.div`       
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    align-items: center;
    justify-content: flex-start;
    z-index: 1000;
    display: flex;      
    transform: ${({show})=> show ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.4s ease-in-out;
    font-family: 'exo', sanf-serif;

    & .modal-container {
        width: 230px;
        height: 70%;
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 25px;
        margin-top: 80px;
        margin-left: 50px;
        background-color: #2E2E2ED9;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        padding: 24px;        
        backdrop-filter: blur(10px);
    }   
`;

const StyledFilterOption = styled.div`
    width: 100%;
    height: 21px;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & .dot-indicator{
        display: ${({isSelected})=>isSelected?'flex':'none'};
        background-color: white;
        border-radius: 100px;
        height: 7px;
        width: 7px;
    }
    
    & p{
        margin: 0px;  
        font-size: 18px;
        font-weight: 500;
        color: ${({isSelected})=>isSelected?'#FFFFFF':'#9AA7AC'};      
    }
`;

function FiltersModal({isOpen, onClose, selectedFilters, setFilter}){

    function onFilterTap(filter){
        //Storing the selected filters for instant manipulation
        let _selectedFilters = selectedFilters;
        //Removing the 'all' option since the 'all' option includes all the other options, so there is no point in keep it when other option is selected
        let allOptionIndex = _selectedFilters.indexOf('all');
        if(allOptionIndex != -1){
            _selectedFilters.splice(allOptionIndex, 1);
        }
        let filterIndex = _selectedFilters.indexOf(filter);
        if(filterIndex >= 0){            
            setFilter(_selectedFilters.toSpliced(filterIndex,1));
        }else{            
            setFilter([..._selectedFilters, filter]);
        }
    }

      return createPortal(
        <StyledFiltersModal show={isOpen} className={`${isOpen ? 'show' : ''}`} onClick={onClose}>
          <div className={`modal-container`} onClick={(e) => e.stopPropagation()}>    
            <StyledFilterOption isSelected={true}>
                <p style={{fontSize: 18}}>Filter By</p>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={true}>
                <p>Item Type</p>                
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('all')} onClick={()=>setFilter(['all'])}>
                <p>All</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('pets')} onClick={()=>onFilterTap('pets')}>
                <p>Pets</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                            
            <StyledFilterOption isSelected={selectedFilters.includes('skins')} onClick={()=>onFilterTap('skins')}>
                <p>Skins</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('qorbs')} onClick={()=>onFilterTap('qorbs')}>
                <p>Qorbs</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('qor_x_pass')} onClick={()=>onFilterTap('qor_x_pass')}>
                <p>Qor-x Pass</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('loot_box')} onClick={()=>onFilterTap('loot_box')}>
                <p>Loot Box</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('eggs')} onClick={()=>onFilterTap('eggs')}>
                <p>Eggs</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('badges')} onClick={()=>onFilterTap('badges')}>
                <p>Badges</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                
            <StyledFilterOption isSelected={true}>
                <p>Blockchain</p>            
            </StyledFilterOption>                
            <StyledFilterOption isSelected={selectedFilters.includes('imx')} onClick={()=>onFilterTap('imx')}>
                <p>IMX</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>                             
            <StyledFilterOption isSelected={selectedFilters.includes('zk_evm')} onClick={()=>onFilterTap('zk_evm')}>
                <p>ZK EVM</p>
                <div className="dot-indicator"></div>
            </StyledFilterOption>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer'}}>
                <div style={{borderRadius: 100, height: 35, width: 83, borderWidth: 1, borderColor: '#FFFFFF', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF'}} onClick={()=>setFilter(['all'])}>
                    Clear
                </div>
                <div style={{borderRadius: 100, height: 35, width: 83, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF', background: 'linear-gradient(#38EF7D, #11998E)', cursor: 'pointer'}}>
                    Filter
                </div>
                <div></div>
            </div>
          </div>
        </StyledFiltersModal>,
        document.getElementById('filterModal')
      );
}

export default FiltersModal;