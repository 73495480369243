import React, { useState } from 'react';
import { Button } from "../components/Button";
import Popup from "../components/Popup";

const TokenMarket = () => {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <div className="elixir-logs-container">
            {showPopup && (
                <Popup
                    onClose={() => setShowPopup(false)}
                    title={`${showPopup.price}`}
                    content={
                        <>
                            <p>
                                $QORBI
                            </p>
                            <p>
                                By clicking Purchase below you will purchase this product. All Sales are Final
                            </p>
                            <Button transparent label="Purchase" onClick={() => setShowPopup(false)} />
                            <Button transparent label="Cancel" onClick={() => setShowPopup(false)} />
                        </>
                    }
                />
            )}
            <h1 className='home-replica-title'>Token Purchase</h1>
            <a
                className="cta-button"
                href="https://www.mexc.com/exchange/QORBI_USDT?utm_source=website&utm_medium=hero&utm_campaign=link"
                target="_blank"
                rel="noopener noreferrer"
            >
                Get $QORBI on MEXC Now! 🚀
            </a>
        </div>
    );
};

export default TokenMarket;