import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import NftCard from '../components/NftCard';
import Lottie from 'react-lottie';
import * as animationData from '../assets/lotties/Arrow_Bottom.json'
import { Context } from '../context/WalletContext';
import Filter from '../assets/svg/Filter';
import CheckBox from '../components/Checkbox';

const Marketplace = () => {
    let navigate = useNavigate();
    const { state, getMarketplace, getMarketPlaceStatus, searchNFT, getFilterOptions } = useContext(Context)
    const limit = 12
    const limitMarket = 100
    const [paginationData, setPaginationData] = useState({ page: 1, totalPages: 1 })
    const [pagination, setPagination] = useState({ babies: [] })
    const [statusMarket, setStatusMarket] = useState({ show: false, desc: '' })
    const [filter, setFilter] = useState([])
    const [filterOpt, setFilterOpt] = useState([])
    const [attributesActives, setAttributesActives] = useState([])
    const [key, setKey] = useState(1)
    const [showPagination, setShowPagination] = useState(false)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    useEffect(() => {
        getBabies()
    }, [])
    const getBabies = async (push = false) => {
        // await getMarketPlaceStatus()
        // console.log(state.marketplaceStatus)
        // if(state.marketplaceStatus && state.marketplaceStatus.mintEnable){
        //     let status =  await getMarketplace(limitMarket)
        //     console.log(status)
        //     if (res.success) {
        //         setStatusMarket({ show: status.data[0].marketStatus, desc: status.data[0].content })
        //         setPagination({ babies: res.data.data })
        //     }
        // }
        // else {
        //     setStatusMarket({ show: state.marketplaceStatus.marketStatus, desc: state.marketplaceStatus[0].content })
        // }
        let status = await getMarketPlaceStatus()
        if (status.success) {
            if (status.data[0].marketStatus) {
                let res = await getMarketplace(limitMarket)
                if (res.success) {
                    setStatusMarket({ show: status.data[0].marketStatus, desc: status.data[0].content })
                    if (push) {
                        let data = pagination.babies.slice()
                        data.push(...res.data.data)
                        setPagination({ babies: data })
                    } else {
                        setPagination({ babies: res.data.data })
                    }
                    setShowPagination(true)
                }
            } else {
                setStatusMarket({ show: status.data[0].marketStatus, desc: status.data[0].content })
            }
        }
        let res = await getFilterOptions()
        if (res.success) {
            setFilterOpt(res.data.data)
        }
    }
    const getBabiesPagination = async () => {
        let res = await searchNFT({ attributes: filter, page: paginationData.page, limit: limit })
        if (res.success) {
            if (res.data.message) {
                setPagination({ babies: [] })
                setPaginationData({ page: 1, totalPages: 1 })
            } else {
                let data = pagination.babies
                data.push(...res.data.data)
                setPagination({ babies: data })
                setPaginationData({ page: paginationData.page + 1, totalPages: res.data.totalPages })
            }
        }
    }
    const clearFilter = () => {
        setFilter([])
        setKey(key + 1)
        setAttributesActives(new Array(filterOpt.length))
        getBabies()
    }
    const submitFilter = async (val) => {
        //enabled or disabled checkbox for battributes example: [name:'sex',value:['male,'female'],name:'hair',value:['green','black'],{name:'eye',value:['blue']}]
        let attr = filter
        let filterVal
        // check if battributes is empty
        if (attr.length > 0) {
            // if not empty get the battribute data  
            filterVal = attr.filter(res => res.name === val.name)
            //check if the battribute is already in the array
            if (filterVal.length > 0) {
                // if is already in the array then check if the checkbox value is true or false
                if (val.val.value) {
                    // if it true then add the new battribute to the value array field
                    filterVal[0].value.push(val.val.name)
                } else {
                    // if it false then get all values different to the one the user remove
                    let newData = filterVal[0].value.filter(res => res !== val.val.name)
                    //check if the all the values from one battributes are off    
                    if (newData.length === 0) {
                        // if all values are off then remove the battribute from the array
                        attr = attr.filter(res => res.name !== val.name)
                    } else {
                        //keep the other values from the battribute
                        filterVal[0].value = newData
                    }
                }
            } else {
                //add the new battribute to the array
                attr.push({ name: val.name, value: [val.val.name] })
            }
        } else {
            // if it empty so add new battribues
            attr.push({ name: val.name, value: [val.val.name] })
        }
        setFilter(attr)
        if (attr.length === 0) {
            getBabies()
        } else {
            let res = await searchNFT({ attributes: attr, page: 1, limit: limit })
            if (res.success) {
                if (res.data.message) {
                    setPagination({ babies: [] })
                    setPaginationData({ page: 1, totalPages: 1 })
                } else {
                    setPagination({ babies: res.data.data })
                    setPaginationData({ page: res.data.currentPage + 1, totalPages: res.data.totalPages })
                }
                setShowPagination(false)
            }
        }
    }
    // const checkNameEntry=(val)=>{
    //     if(filter.name===''){
    //         if(val.length >= 3) submitFilter('name',val)
    //     }else{
    //         submitFilter('name',val)
    //     }
    // }
    return (
        <div className='darkgreen-bg'>
            <div className='section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center', marginBottom: 20 }}>
                            <img alt='marketplace' src='../assets/img/baby-market.png' style={{ marginBottom: 20, width: '60%' }} />
                        </div>
                    </div>
                    {statusMarket.show ?
                        <div className='row'>
                            <div className='col-12 col-md-2 filter-container'>
                                <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: 10 }}>
                                    <span className='label'>Filter by:</span>
                                    <div className='filterIcon' onClick={() => clearFilter()}>
                                        <div className='clear'>/</div>
                                        <Filter />
                                    </div>
                                </div>
                                <div className='tab'>
                                    <div className='content enabled'>
                                        {
                                            filterOpt.map((item, index) => (
                                                <div className='show-content' key={`filter-${index}`}>
                                                    <label className='sublabel' onClick={() => {
                                                        let data = attributesActives.slice()
                                                        attributesActives[index] ? data[index] = false : data[index] = true
                                                        setAttributesActives(data)
                                                    }}>{item.name} <i className={`fa ${attributesActives[index] ? 'fa-minus' : 'fa-plus'}`} /></label>
                                                    <div className={`subcontent ${attributesActives[index] ? 'show' : ''}`}>
                                                        {item.values.map((item2, index2) => (
                                                            <CheckBox name={item2} onClick={(val) => submitFilter({ name: item.name, val: val })} key={`filter${item}-${index2}`} />
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-10'>
                                <div className='row'>
                                    {
                                        pagination.babies.length > 0 ?
                                            pagination.babies.map((item, index) => (
                                                <div className='col-12 col-md-6 col-lg-3 nft-card' key={index}>
                                                    <NftCard item={item} goTo={(val) => navigate(`/baby-nft/${val}`)} />
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            {
                                paginationData.totalPages >= paginationData.page && filter.length > 0 ?
                                    <div className='col-12'>
                                        <div className='lottie' onClick={() => getBabiesPagination()}><span className='load-more'>Load More</span><Lottie options={defaultOptions} height={90} width={88} /></div>
                                    </div>
                                    : null
                            }
                            {
                                showPagination ?
                                    <div className='col-12'>
                                        <div className='lottie' onClick={() => getBabies(true)}><span className='load-more'>Load More</span><Lottie options={defaultOptions} height={90} width={88} /></div>
                                    </div>
                                    : null
                            }
                        </div>
                        :
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='green-title'>{statusMarket.desc}</h2>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default Marketplace
