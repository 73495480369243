import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { marketItems } from "../utils/Global";
// import { Context } from "../context/WalletContext";

// const immutableServices = require('../context/ImmutableService');

const Market = () => {

  // const { state } = useContext(Context);

  let navigate = useNavigate();

  // const isQorbOwner = async () => {
  //   const isQorbOwner = await immutableServices.assetOwner(state.user.data, 'qorb')
  //   const isQorXOwner = await immutableServices.assetOwner(state.user.data, 'qorx')
    
  //   if (!(isQorXOwner || isQorbOwner)) {
  //     navigate(`/market`)
  //   }
  // }

  useEffect(() => {
    // isQorbOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="darkbg market">
      <div className="section mt-5">
        <div className="container">
          <div className="row mt-5 align-items-stretch justify-content-center">
            {/* <h1 style={{ color: '#51E180', fontSize: '40px', fontWeight: 'bold', marginBottom: '30px' }}>Discover, Trade, and Conquer: Your Journey Begins in the Qorbi World Market!</h1> */}
            <h1 style={{ color: '#FF6347', fontSize: '40px', fontWeight: 'bold', marginBottom: '30px', textAlign: 'center', textShadow: '2px 2px 8px rgba(255, 99, 71, 0.7)', }}>
              🎄 Discover, Trade, and Celebrate: Your Holiday Adventure Begins in the Qorbi World Market! 🎁
            </h1>
            {marketItems.filter(item => item.source === "Private Market" && item.id !== 24).map((item) => (
              item?.url &&
              <div className="col-12 col-md-4" style={{ marginBottom: 45, position: 'relative', paddingBottom: 60 }} key={`item-${item.id}}`}>
                <div className="nft-card" style={{ boxShadow: "-2px 2px 14px 1px #51E180", borderRadius: "8px", height: "100%", marginBottom: 40 }}>
                  <div style={{ padding: "30px" }}>
                    <div
                      className="nft-bg"
                      style={{ backgroundImage: `url(${item.img})`, width: 400, maxWidth: "100%", margin: "0 auto" }}
                      onClick={() => {
                        navigate(`/single/${item.id}`);
                      }}
                    >
                      <div className="sold-out-text" style={{ transform: "rotate(-45deg)", top: "40%", fontSize: "50px" }}>¡Limited 200!</div>
                    </div>
                  </div>
                  <div className="content" style={{ background: "transparent", position: 'relative' }}>
                    <h4 className="fs-6 txt-green">
                      {item.name}
                    </h4>
                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: item.desc }} />
                    <div
                      className="txt-green mb-4 mt-4"
                      style={{ height: 30, fontSize: 24, textAlign: 'center' }}
                    >
                      ${item.price}.00
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10, position: "absolute", left: 0, width: "100%", paddingLeft: 20, paddingRight: 20, bottom: 60 }}>
                  <button
                    className="greenBtn"
                    style={{ width: "100%", marginRight: "10px", height: 50 }}
                    onClick={() => {
                      navigate(`/single/${item.id}`);
                    }}
                  >
                    🎁 Mint Now 🎉
                  </button>
                  {item.moreUrl !== "" &&
                    <a
                      className="btnTransparent"
                      style={{ width: "100%", marginLeft: "10px", height: 50 }}
                      href={item.moreUrl}
                      target="blank"
                    >
                      Learn More
                    </a>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
