import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { ImmutableXClient } from "@imtbl/imx-sdk";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import styled from "styled-components";
import GradientOutlinedButton from "../components/GradientOutlinedButton";
import QorbSelector from "../components/QorbSelector";
import GreenIsotype from "../assets/img/Green_Isotype.png";
import InputWallet from "../components/InputWallet";
import { Context } from "../context/WalletContext";
import { passportInstance } from "../context/PassportService";

const qorbiService = require("../context/QorbiService");

const StyledQorbiStakingPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 5%;

  @media (width <= 900px) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 30%;
  }
`;
const StyledImageAndPoolDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 900px) {
    justify-content: start;
    align-items: center;
    width: 100% !important;
  }
`;
const StyledAssetImage = styled.div`
  height: 350px;
  width: 600px;
  border-radius: 40px;
  background: linear-gradient(0.25turn, #ffffff00, #38ef7d, #ffffff00);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    object-fit: cover;
    height: 348px;
    width: 598px;
    border-radius: 40px;
  }

  @media (width <= 900px) {
    width: 70vw !important;
    height: 200px !important;

    & img {
      width: 70vw !important;
      height: 198px;
    }
  }
`;
const StyledPledgesDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-family: "exo", sans-serif;

  .items-count {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23.92px;
    color: #a6a6a6;
  }

  .qorb-earning-container {
    width: 600px;
    height: 174px;
    border-radius: 32px;
    background: #ffffff0d;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .qorbi-earnings-title {
      color: #ffffff;
      font-size: 22px;
      line-height: 26.4px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .qorbi-earnings-quantity {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      width: 100px;
    }
  }

  .playing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    border-radius: 14px;
    border-width: 1px;
    border-color: #38ef7d;
    border-style: solid;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
  }

  .pool-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    background: transparent;
    border-radius: 14px;
    border-width: 2px;
    border-color: #ffd277;
    border-style: solid;
    color: #ffd277;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
  }

  .withdrwaw-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    background: linear-gradient(#38ef7d, #11998e);
    border-radius: 14px;
    border-width: 0px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
  }

  @media (width <= 900px) {
    width: 100% !important;

    .qorb-earning-container {
      width: 80% !important;
      margin-bottom: 0px;
    }

    .playing-button {
      width: 80% !important;
    }

    .pool-button {
      width: 80% !important;
    }

    .withdrwaw-button {
      width: 80% !important;
    }
  }
`;
const StyledPoolDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 32px;
  width: 600px;
  padding: 24px;
  background-color: #ffffff0d;
  font-family: "exo", sans-serif;

  .title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.4px;
  }

  .pool-data-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 24px;

    .data-item-title {
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
      line-height: 21.6px;
    }

    .data-item-value {
      color: #38ef7d;
      font-weight: 300;
      font-size: 18px;
      line-height: 21.6px;
    }
  }

  @media (width <= 900px) {
    width: 80%;
    margin-bottom: 25px;
    margin-top: 25px;

    .pool-data-item {
      flex-direction: column;
    }
  }
`;
const StyledPledginHistoryContainer = styled.div`
    border-radius: 32px;
    background: #FFFFFF0D;
    width: 449px;
    display: flex;    
    flex-direction: column;
    justify-content: flex-start;
    align-items: strech;
    padding: 24px;
    overflow-y: scroll;          
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-family: 'exo', sans-serif;

    #pledging-header {
      display: flex;
      justify-content: space-between;
      align-items: center;                   
      width: 100%;
    }

    #history-title {       
      font-weight: 500;
      font-size: 22px;
      line-height: 26.4px;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    @media (width > 900px) {
      height: 700px;
    }

    @media (width <= 900px) {
      width: 80% !important;
      padding: 15px 10px 10px 10px;

      #pledging-header {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;                    
        width: 100%;
      }

      .record-id {
        padding-right: 5px !important;
      } 
    }
    
    & .record-date {
        color: #FFFFFF,
        font-weight: 300;
        font-size: 18px;
        line-height: 21.6px;
      }

    & .record-id {
        padding-right: 30px;
        color: #38EF7D;
      }      
    }    
`;
const StyledLoadingComponent = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  p {
    color: #ffffff;
    margin: 0px;
  }
`;

const QorbStaking = () => {
  const { logOut } = useContext(Context);
  const [qorbStaking, setQorbStaking] = useState(null);
  const [currentQorb, setCurrentQorb] = useState(null);
  const [qorbiBalance, setQorbiBalance] = useState(null);
  const [allQorbs, setAllQorbs] = useState(null);
  const [walletInput, setWalletInput] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const firstID = urlParams.get("qorb");
  const qorbWallet = urlParams.get("wallet");
  const qorbSource = urlParams.get("source");

  const logoutService = async () => {
    const currentSession = localStorage.getItem("userInfo");
    const infoFromString = JSON.parse(currentSession);
    localStorage.removeItem("userInfo");
    try {
      if (infoFromString.authType === "ImmutablePassport") {
        await passportInstance.logout();
      } else {
        navigate("/my-assets");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPoolBalance = async () => {
    try {
      const balanceData = await qorbiService.qorbiBalance();
      const balanceWithoutZeros = parseFloat(balanceData.balance - 244462.51);
      setQorbiBalance(balanceWithoutZeros);
    } catch (error) {
      setError(true);
      if (error.message === "Request failed with status code 401") {
        logOut();
        logoutService();
      }
    }
  };

  const getQorbDatas = async () => {
    try {
      await getPoolBalance();
      const client = await ImmutableXClient.build({
        publicApiUrl: "https://api.x.immutable.com/v1",
      });
      if (qorbWallet !== undefined) {
        const qorbAssetsRequest = await client.getAssets({
          user: qorbWallet,
          collection: "0x2d59c7b8d253b826cdba9389be397a68af88cf08",
        });
        setAllQorbs(qorbAssetsRequest.result);
        const baseUrl = `https://immutable-mainnet.blockscout.com/api/v2/tokens/0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D/instances?holder_address_hash=${qorbWallet}`;
        let unique_token = null;
        while (true) {
          let url = unique_token ? baseUrl + unique_token : baseUrl;
          const zkEvm = await axios.get(url);
          const nftMetadataPromises = zkEvm.data.items.map(async (token) => {
            const tokenId = token.id;
            try {
              const metadataResponse = await fetch(
                `https://assets.qorbiworld.com/resource/qorb/${tokenId}`
              );
              if (!metadataResponse.ok) {
                throw new Error(
                  `Error fetching metadata for token ${tokenId}: ${metadataResponse.statusText}`
                );
              }
              const metadata = await metadataResponse.json();
              return {
                ...token,
                metadata,
              };
            } catch (error) {
              console.error(
                `Error fetching metadata for token ${tokenId} in collection qorb:`,
                error
              );
              return null;
            }
          });

          const nftsWithMetadata = (
            await Promise.all(nftMetadataPromises)
          ).filter((nft) => {
            const name = nft?.metadata?.name;
            return (
              name !== "One Qorbi Burn" &&
              name !== "One Tap Operative Mystery Box"
            );
          });
          setAllQorbs((prevQorbs) => [...prevQorbs, ...nftsWithMetadata]);
          if (
            zkEvm.data.items.length === 0 &&
            qorbAssetsRequest.result.length === 0
          ) {
            navigate("/my-assets");
          }
          if (zkEvm.data.next_page_params) {
            unique_token = `&unique_token=${zkEvm.data.next_page_params.unique_token}`;
          } else {
            break;
          }
        }
        if (qorbSource === "IMX") {
          const filteredQorb = qorbAssetsRequest.result.find(
            (token) => token.token_id === firstID
          );
          setCurrentQorb(filteredQorb);
        } else {
          const metadataResponse = await fetch(
            `https://assets.qorbiworld.com/resource/qorb/${firstID}`
          );
          const metadata = await metadataResponse.json();
          setCurrentQorb({ metadata });
        }

        await fetchData();
      }
    } catch (error) {
      setError(true);
    }
  };

  async function switchCurrentQorb(qorbId) {
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (uuidPattern.test(qorbWallet)) {
      const filteredQorb = allQorbs.find((token) => token.id === qorbId);
      setCurrentQorb(filteredQorb);
    } else {
      if (qorbSource === "IMX") {
        const filteredQorb = allQorbs.find(
          (token) => token.token_id === qorbId
        );
        setCurrentQorb(filteredQorb);
      } else {
        const metadataResponse = await fetch(
          `https://assets.qorbiworld.com/resource/qorb/${qorbId}`
        );
        const metadata = await metadataResponse.json();
        setCurrentQorb({ metadata });
      }
    }
  }

  const fetchData = async () => {
    try {
      const data = await qorbiService.getQorbStaking(qorbWallet)
      setQorbStaking(data);
    } catch (error) {
      setError(true);
      console.error("Error fetching Qorb data:", error.message);
    }
  };

  const addToPool = async (target) => {
    const chainItem = qorbSource === "IMX" ? "immutablex" : "zkevm";
    if (
      !qorbStaking?.isPoolQorb.includes(firstID) ||
      (!qorbStaking.playingQorb && target === "Playing")
    ) {
      const flagQorb = await qorbiService.addQorbToPool({
        walletAddress: qorbWallet,
        QorXNftID: firstID,
        target,
        qorbName:
          currentQorb?.name ||
          currentQorb?.metadata?.name ||
          currentQorb?.extra?.attributes[0]?.value,
        qorbImage:
          currentQorb?.image_url ||
          currentQorb?.extra?.imageUrl ||
          currentQorb?.metadata?.image,
        chain: chainItem,
      });
      if (flagQorb.addedToQorbPool) {
        window.location.reload();
      }
    }
  };

  const handleWithdraw = async (address) => {
    setWalletInput(false);
    setShowPopup({
      title: "Starting...",
      content: "Withdrawing QORBI from QORB",
    });
    await qorbiService.withdrawQorb(qorbStaking.totalReward, address);
    // await qorbiService.withdrawQorb('1', address);
    window.location.reload();
  };

  useEffect(() => {
    async function loadData() {
      try {
        await getQorbDatas();
        setError(false);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComputerView = () => {
    return (
      <>
        {isLoading ? (
          <StyledLoadingComponent>
            <img alt='Green Isotype' src={GreenIsotype}></img>
            <p>Loading Asset...</p>
          </StyledLoadingComponent>
        ) : error ? (
          <StyledLoadingComponent>
            <img alt='Green Isotype' src={GreenIsotype}></img>
            <p>Something Went Wrong</p>
            <p>While Loading Your Asset</p>
          </StyledLoadingComponent>
        ) : (
          <>
            {walletInput && <InputWallet onSuccess={handleWithdraw} />}
            {showPopup && (
              <Popup
                onClose={() => setShowPopup(false)}
                title={showPopup.title}
                content={
                  <>
                    <p>{showPopup.content}</p>
                  </>
                }
              />
            )}
            {qorbStaking && (
              <StyledQorbiStakingPage>
                <StyledImageAndPoolDetailsContainer>
                  <StyledAssetImage>
                    <img
                      src={
                        currentQorb?.image_url ||
                        currentQorb?.extra?.imageUrl ||
                        currentQorb?.metadata?.image
                      }
                      alt="QorX"
                    />
                  </StyledAssetImage>
                  <StyledPledgesDetails>
                    <GradientOutlinedButton
                      text={`${currentQorb?.name ||
                        currentQorb?.metadata?.name ||
                        currentQorb?.extra?.attributes[0]?.value
                        } #${firstID}`}
                      onTap={function () { }}
                    ></GradientOutlinedButton>
                    {Object.keys(qorbStaking?.groups).length > 0 && (
                      <div className="items-count">
                        Qorbs Owned: {allQorbs.length}
                      </div>
                    )}
                    <br></br>
                    <div className="qorb-earning-container">
                      <QorbSelector
                        key={"qorb_detail_qorb_selector"}
                        selectedQorb={`${currentQorb?.name ||
                          currentQorb?.metadata?.name ||
                          currentQorb?.extra?.attributes[0]?.value
                          }`}
                        qorbs={allQorbs}
                        onQorbSelected={switchCurrentQorb}
                      ></QorbSelector>
                      <div className="qorbi-earnings-title">Qorbi Earned:</div>
                      <div className="qorbi-earnings-quantity">
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.6953 13.6675C16.5481 12.4474 17.0504 11.0165 17.1472 9.53061C17.2441 8.04467 16.932 6.56059 16.2448 5.23992C15.5575 3.91924 14.5216 2.81258 13.2497 2.04037C11.9778 1.26817 10.5186 0.860024 9.03108 0.860355C8.66332 0.860006 8.29598 0.884966 7.93164 0.935058V2.70669C8.3488 2.62195 8.77333 2.5788 9.19899 2.57789C10.5044 2.57682 11.7787 2.97734 12.8492 3.72523C13.9197 4.47312 14.7348 5.53225 15.184 6.75922C15.6333 7.98619 15.6951 9.32173 15.361 10.585C15.0269 11.8483 14.3131 12.9783 13.3163 13.822L14.2253 14.7436L14.5 15.0224L16.3791 16.9299H19.1023L15.6927 13.6655L15.6953 13.6675Z"
                            fill="white"
                          />
                          <path
                            d="M12.5828 14.3629C11.2087 15.237 9.55246 15.5512 7.95422 15.2409C6.35597 14.9306 4.93711 14.0195 3.9891 12.6946C3.04108 11.3696 2.63591 9.73162 2.8568 8.11698C3.07769 6.50233 3.90788 5.03366 5.17682 4.01268L5.19741 9.02166C5.19741 10.0733 5.61472 11.0818 6.35754 11.8254C7.10036 12.569 8.10784 12.9867 9.15835 12.9867C9.6827 12.9917 10.2028 12.8923 10.6885 12.6945C11.1742 12.4967 11.6159 12.2043 11.9878 11.8343C12.3598 11.4643 12.6547 11.0241 12.8554 10.5391C13.0561 10.0542 13.1587 9.53419 13.1571 9.00928C13.1555 8.48436 13.0498 7.96498 12.8462 7.48126C12.6426 6.99753 12.3451 6.55908 11.9709 6.19134C11.5967 5.8236 11.1533 5.53387 10.6664 5.33897C10.1796 5.14407 9.65886 5.04787 9.13455 5.05595C8.71486 5.05785 8.29815 5.12654 7.90001 5.25945V7.20753C8.29579 6.90956 8.7803 6.75373 9.27539 6.7652C9.77047 6.77667 10.2473 6.95476 10.6289 7.27074C11.0104 7.58672 11.2746 8.02217 11.3786 8.50684C11.4827 8.99151 11.4206 9.49714 11.2024 9.94216C10.9842 10.3872 10.6226 10.7456 10.1759 10.9597C9.72924 11.1738 9.22355 11.231 8.74043 11.1221C8.2573 11.0132 7.82491 10.7446 7.513 10.3595C7.20109 9.97447 7.02785 9.49546 7.02123 8.99977L7.00643 5.69221L7.02123 2.95717V1.11084C5.10116 1.60098 3.42593 2.77603 2.31018 4.41526C1.19444 6.0545 0.71496 8.04513 0.961805 10.0133C1.20865 11.9814 2.16484 13.7916 3.65076 15.1038C5.13669 16.4161 7.05011 17.1401 9.03162 17.1399C9.12361 17.1399 9.21561 17.1399 9.30567 17.1347V17.1399L15.1953 16.9306L13.78 15.6084L12.5821 14.3636L12.5828 14.3629Z"
                            fill="white"
                          />
                        </svg>
                        {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                      </div>
                    </div>
                    <button className='withdrwaw-button' onClick={() => setWalletInput(true)}>
                      Withdraw Earning
                    </button>
                    {!qorbStaking?.isPoolQorb.includes(firstID) &&
                      (!qorbStaking.playingQorb ||
                        qorbStaking.playingQorb === firstID) && (
                        <div
                          className="playing-button"
                          onClick={() => addToPool("Playing")}
                        >
                          {qorbStaking?.playingQorb === firstID
                            ? "Playing"
                            : "Play With Qorb"}
                        </div>
                      )}
                    {qorbStaking?.playingQorb !== firstID && (
                      <button
                        onClick={() => addToPool("Enter Pool")}
                        className="pool-button"
                      >
                        In Pool
                      </button>
                    )}
                  </StyledPledgesDetails>
                </StyledImageAndPoolDetailsContainer>
                <div style={{ width: 35 }}></div>
                <StyledPoolDataContainer>
                  <p className="title">Summary</p>
                  <div style={{ height: 32 }}></div>
                  <div className="pool-data-item">
                    <p className="data-item-title">Total Earned In Pool:</p>
                    <p className="data-item-value">{qorbiBalance} $QORBI</p>
                  </div>
                  {/* <div className="pool-data-item">
                    <p className="data-item-title">Total Pool:</p>
                    <p className="data-item-value">{poolBalance} $EMBER</p>
                  </div>
                  <div className="pool-data-item">
                    <p className="data-item-title">Next Distribution Date:</p>
                    <p className="data-item-value">Coming Soon</p>
                  </div>
                  <div className="pool-data-item">
                    <p className="data-item-title">My Ember In Pool:</p>
                    <p className="data-item-value">
                      {qorbiUserData?.emberContribution?.[0]?.totalAmount *
                        -1 || 0}{" "}
                      $EMBER
                    </p>
                  </div>
                   */}
                  <div className="pool-data-item">
                    <p className="data-item-title">Ember Contributor Bonus:</p>
                    <p className="data-item-value">674420.23 $QORBI</p>
                  </div>
                </StyledPoolDataContainer>
                <div style={{ width: 35 }}></div>
                {allQorbs?.length > 0 && (
                  <StyledPledginHistoryContainer>
                    <div style={{ color: "white" }}>
                      <div id="pledging-header">
                        <div id="history-title">History of Pledging</div>
                        <QorbSelector
                          key="history_pleding_qorb_selector"
                          selectedQorb={`${currentQorb?.name ||
                            currentQorb?.metadata?.name ||
                            currentQorb?.extra?.attributes[0]?.value
                            }`}
                          qorbs={allQorbs}
                          onQorbSelected={switchCurrentQorb}
                        ></QorbSelector>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: "#323232",
                          borderRadius: "15px",
                          padding: 10,
                        }}
                      >
                        <div>Date</div>
                        <div>Reward</div>
                      </div>
                      {qorbStaking?.groups[
                        currentQorb.token_id || currentQorb.id
                      ]?.length > 0 &&
                        qorbStaking.groups[
                          currentQorb.token_id || currentQorb.id
                        ]
                          .slice()
                          .map((row) => (
                            <div
                              key={row.token_id || row.id}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: "10px",
                              }}
                            >
                              <div className="record-date">{row.day}</div>
                              <div className="record-id">
                                {row.customReward || row.reward}
                              </div>
                            </div>
                          ))}
                    </div>
                  </StyledPledginHistoryContainer>
                )}
              </StyledQorbiStakingPage>
            )}
          </>
        )}
      </>
    );
  };

  return renderComputerView();
};

export default QorbStaking;
