import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavBar from "../components/Header/NavBar";
// import Snowfall from "../components/Snowflakes.js";
import Home from "../screens/Home";
import { Footer } from "../components/Footer";
import Lottie from "react-lottie";
import * as animationData from "../assets/lotties/Dot_Loading.json";
import { ImmutablePassport, PopUp, Toast } from "../utils/Global";
import { Context } from "../context/WalletContext";
// import HowToBuy from "../screens/HowToBuy";
import Popup from "../components/Popup";
import Mint from "../screens/Mint";
import EggMint from "../screens/EggMint.js";
import SuccessPage from "../screens/SuccessPage";
import FailedPage from "../screens/FailedPage";
import Games from "../screens/Games";
import Qorbi from "../screens/Qorbi";
import ThankYou from "../screens/ThankYou";
import MyAssets from "../screens/MyAssets";
import PlayOneTap from "../screens/PlayOneTap";
import MintForm from "../screens/mintform";
import Market from "../screens/Market";
import PrivateMarket from "../screens/PrivateMarket.js";
// import DailyGamers from "../screens/DailyGamers";
import NftDetails from "../screens/NftDetails.js";
import SingleMarket from "../screens/SingleMarket";
// import GamerPrizes from "../screens/gamerprizes";
import RegisterWalletOverlay from "../components/RegisterWalletOverlay.js";
import QorX from "../screens/QorX.js";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import LoadingSVG from "../assets/svg/Loading";
import axios from "axios";
import OpenLootBox from "../screens/OpenLootBox.js";
import RegisterForm from "../components/RegisterForm.js";
import QorShop from "../screens/QorShop.js";
import MyBundles from "../screens/MyBundles.js";
import QorbCollection from "../screens/QorbCollection.js";
import ElixirLogs from "../screens/ElixirLogs.js";
import GameLogs from "../screens/GameLogs.js";
import QorbabyConfirmation from "../screens/QorbabyConfirmation.js";
import QorbStaking from "../screens/QorbStaking.js";
import StartMissionsMessage from "../components/StartMission.js";
import SkinTrade from "../screens/SkinTrade.js";
import Bundles from "../screens/Bundles.js";
import EggTrade from "../screens/EggTrade.js";
import BetaTester from "../screens/BetaTester.js";
import UserProfile from "../screens/UserProfile.js";
import BetaCountdown from "../screens/Countdown.js";
import { passportInstance } from "../context/PassportService.js";
import LeaderboardPage from "../screens/GlobalRanks.js";
import ZkMarketplace from "../screens/MarketplaceZkEVM.js";
import JoinPool from "../screens/JoinPool.js";
// import Babies from "../screens/Babies.js";
// import Stake from "../screens/Stake.js";
import Marketplace from "../screens/OldMarketplace.js";
import TokenMarket from "../screens/TokenMarket.js";
import Testing from "../screens/Testing.js";
import OpenMysteryBox from "../screens/OpenMysteryBox.js";
import HistoryOfPledging from "../screens/HistoryOfPledging.jsx";
import Download from "../screens/Download.js";
import VotePage from "../screens/Voting.js";
// import UnderMaintenance from "../screens/UnderMaintenance.js";

const qorbiServices = require("../context/QorbiService");

let isLoggedIn;

const CustomRoute = ({ Component, path, ...rest }) => {
  if (isLoggedIn) {
    return <Component {...rest} />;
  } else {
    localStorage.setItem("unauthorizedPath", path);
    return <Navigate to="/profile" />;
  }
};

export const Routers = () => {
  const { state, localLogin, LoginWalletEmail, immutableLogin, metamaskLogin } =
    useContext(Context);
  const [toast, setToast] = useState({ show: false, type: "", desc: "" });
  const [showPopup, setShowPopup] = useState({});
  const session = localStorage.getItem("session");
  if (session !== null || state.user !== null) {
    isLoggedIn = true;
  }
  const storedItem = localStorage.getItem("unauthorizedPath");
  const [ready, setReady] = useState(false);
  const {
    setQorbiUserData,
    setDevicedUsed,
    setRegisterWallet,
    setRegisterUser,
    registerWallet,
    registerUser,
    startMission,
  } = useSphereOneProvider();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const isIMX = async (address) => {
    try {
      const immutableResponse = await axios.get(
        `https://api.x.immutable.com/v1/users/${address}`
      );
      if (immutableResponse.data) {
        return true;
      }
    } catch (immutableError) {
      return false;
    }
  };

  const connectPassport = async () => {
    const gameTracker = localStorage.getItem("oneTapTrackId");
    const provider = passportInstance.connectEvm();
    const accounts = await provider.request({ method: "eth_requestAccounts" });
    const passportAccessToken = await passportInstance.getAccessToken();
    const newUser = await qorbiServices.authPassport(passportAccessToken);
    if (newUser) {
      setRegisterUser(true);
    } else if (gameTracker) {
      await qorbiServices.authUnity(gameTracker);
      localStorage.removeItem("oneTapTrackId");
    }
    localStorage.setItem("WALLET_ADDRESS", accounts[0]);
    try {
      const emailUser = await qorbiServices.userByToken();
      const walletObject = {
        address: accounts[0],
        qorbiWallet: emailUser.qorbiWallet,
        mintingWallet: emailUser.mintingWallet,
        wallets: emailUser.wallets ?? [],
      };
      setQorbiUserData(emailUser);
      LoginWalletEmail(walletObject, accounts);
    } catch (error) {
      console.error(error);
    }
    setReady(true);
    if (storedItem) {
      localStorage.removeItem("unauthorizedPath");
      window.location.href = storedItem;
    }
  };

  const validateWallet = async (address) => {
    await metamaskLogin();
    const isImx = await isIMX(address);
    if (isImx) {
      try {
        const qorbiUser = await qorbiServices.userByToken();
        setQorbiUserData(qorbiUser);
        if (
          !qorbiUser.email ||
          qorbiUser.username.startsWith("not-username-")
        ) {
          setRegisterUser(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setRegisterWallet({ show: true, wallet: address });
    }
  };

  useEffect(() => {
    Toast.get().subscribe((res) => {
      setToast({ show: res.show, type: res.type, desc: res.desc });
      if (res.show) {
        setTimeout(() => {
          Toast.set({ show: false, type: "", desc: "" });
        }, 1000000);
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    const oneTapTrackId = urlParams.get("auth");
    if (oneTapTrackId) {
      localStorage.setItem("oneTapTrackId", oneTapTrackId);
    }
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      setReady(true);
      const userInfo = JSON.parse(userInfoString);
      localLogin(userInfo);
      if (userInfo.authType === ImmutablePassport) {
        passportInstance.loginCallback();
        connectPassport();
      } else {
        validateWallet(userInfo.data);
        setReady(true);
      }
    } else {
      setReady(true);
    }

    PopUp.get().subscribe((res) => {
      setShowPopup(res);
    });

    if (isMobile) {
      setDevicedUsed("mobile");
    } else {
      setDevicedUsed("computer");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectImmutable = async () => {
    if (localStorage.getItem("WALLET_ADDRESS")) {
      localStorage.removeItem("STARK_PUBLIC_KEY");
      localStorage.removeItem("WALLET_ADDRESS");
    }
    try {
      if ("ethereum" in window) {
        const userAddress = await immutableLogin();
        if (userAddress.data) {
          setRegisterWallet({ show: false, wallet: userAddress.data });
          window.location.reload();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadingOpt = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return ready ? (
    <div>
      {/* <Snowfall></Snowfall> */}
      {registerWallet.show && (
        <RegisterWalletOverlay
          registerImmutable={connectImmutable}
          unregisteredWallet={registerWallet.wallet}
        ></RegisterWalletOverlay>
      )}
      {registerUser && <RegisterForm></RegisterForm>}
      {startMission && <StartMissionsMessage></StartMissionsMessage>}
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/1" element={<Marketplace />} />
          {/*<Route path="/" element={<HowToBuy />} />{/*Refactor to make into instruction page*/}
          <Route path="/qorbi" element={<Qorbi />} />
          {/*<Route path="/" element={<DailyGamers />} />{/*Refactor to make into matches history page*/}
          {/*<Route path="/" element={<GamerPrizes />} /> {/*Refactor to make into rules page*/}
          <Route path="/market" element={<Market />} />
          <Route path="/qorshop" element={<QorShop />} />
          <Route path="/bundles" element={<Bundles />} />
          <Route path="/failed" element={<FailedPage />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/elixir" element={<ElixirLogs />} />
          <Route path="/gamelogs" element={<GameLogs />} />
          <Route path="/my-assets" element={<MyAssets />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/single/:id" element={<SingleMarket />} />
          <Route path="/my-bundles/:id" element={<MyBundles />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/private-market" element={<PrivateMarket />} />
          <Route path="/qorb-collection" element={<QorbCollection />} />
          <Route path="/marketplace" element={<ZkMarketplace />} />
          <Route path="/token-market" element={<TokenMarket />} />
          <Route path="/pool" element={<JoinPool />} />
          <Route path="/close-beta-countdown" element={<BetaCountdown />} />
          <Route
            path="/qorbaby-confirmation"
            element={<QorbabyConfirmation />}
          />
          <Route
            path="/nft-details/:collection/:token_id"
            element={<NftDetails />}
          />
          <Route path="/playairdrop" element={<Download />} />
          <Route path="/pledging-history" element={<HistoryOfPledging />} />
          {/* Custom Routes */}
          <Route
            path="/mint"
            element={<CustomRoute path="/mint" Component={Mint} />}
          />
          <Route
            path="/mrpf"
            element={<CustomRoute path="/mrpf" Component={Testing} />}
          />
          <Route
            path="/qorx"
            element={<CustomRoute path="/qorx" Component={QorX} />}
          />
          {/* <Route path="/token-market" element={<CustomRoute path="/token-market" Component={TokenMarket} />} /> */}
          <Route
            path="/eggmint"
            element={<CustomRoute path="/eggmint" Component={EggMint} />}
          />
          <Route
            path="/kitchen"
            element={<CustomRoute path="/kitchen" Component={EggTrade} />}
          />
          <Route
            path="/mystery-box"
            element={
              <CustomRoute path="/mystery-box" Component={OpenMysteryBox} />
            }
          />
          <Route
            path="/mintform"
            element={<CustomRoute path="/mintform" Component={MintForm} />}
          />
          <Route
            path="/skin-forge"
            element={<CustomRoute path="/skin-forge" Component={SkinTrade} />}
          />
          <Route
            path="/beta-tester"
            element={<CustomRoute path="/beta-tester" Component={BetaTester} />}
          />
          <Route
            path="/openlootbox"
            element={
              <CustomRoute path="/openlootbox" Component={OpenLootBox} />
            }
          />
          <Route
            path="/play-one-tap"
            element={
              <CustomRoute path="/play-one-tap" Component={PlayOneTap} />
            }
          />
          <Route
            path="/qorb-vote"
            element={<CustomRoute path="/qorb-vote" Component={VotePage} />}
          />
          <Route
            path="/qorb-staking"
            element={
              <CustomRoute path="/qorb-staking" Component={QorbStaking} />
            }
          />
          {/* Main Route */}
          <Route
            path="/games"
            element={
              storedItem === null ? (
                <Games />
              ) : (
                (() => {
                  if (
                    (window.location.pathname === "/games" ||
                      window.location.pathname === "/") &&
                    isLoggedIn
                  ) {
                    return <Navigate to={storedItem} />;
                  } else {
                    return <Games />;
                  }
                })()
              )
            }
          />
        </Routes>
        <Footer />
      </Router>
      {toast.show ? (
        <div className="position-fixed alert-container">
          <div
            className="close"
            onClick={() => Toast.set({ show: false, desc: "", type: "" })}
          />
          <div className="toast-container">
            <div
              className={`toast-icon ${
                toast.type === "success" ? "toast-success" : "toast-error"
              }`}
            >
              {toast.type === "success" ? (
                <i className="fa fa-check-circle" />
              ) : (
                <i className="fa fa-exclamation-circle" />
              )}
              <h2>{toast.type === "success" ? "Well Done" : "Error"}</h2>
            </div>
            <div className="toast-content">{toast.desc}</div>
            <button
              className="btnTransparent"
              onClick={() => Toast.set({ show: false, desc: "", type: "" })}
            >
              close
            </button>
          </div>
        </div>
      ) : null}
      {state.loading ? (
        <div className="loading-container">
          <Lottie options={loadingOpt} height={90} width={88} />
          {state.loadingText ? (
            <div className="loading-text">{state.loadingText}</div>
          ) : null}
        </div>
      ) : null}
      {showPopup.show ? (
        <Popup
          title={showPopup.title}
          content={showPopup.content}
          onClose={() => PopUp.set({ show: false, title: "", content: null })}
        />
      ) : null}
    </div>
  ) : (
    <div
      className="loadScreen"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src="/assets/img/White_Full_Logo.png"
        alt="sticky brand-logo"
        width={120}
        style={{ marginBottom: "20px" }}
      />
      <div style={{ textAlign: "center", color: "white" }}>
        <h2 style={{ fontSize: "28px", marginBottom: "10px" }}>
          Welcome to Qorbi World
        </h2>
        <p style={{ fontSize: "20px", marginBottom: "20px" }}>
          Loading the guns and fetching the eggs
        </p>
        <LoadingSVG></LoadingSVG>
        <p style={{ fontSize: "18px", marginTop: "20px" }}>
          Hang tight, we're getting all ready...
        </p>
      </div>
    </div>
  );
};
