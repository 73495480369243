import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { disconnectWallet, setWalletActionDefault } from "../context/QorbiService";
import {Context} from "../context/WalletContext";
import ChangeWalletModal from "../components/Modals/ChangeWalletModal";

const StyledWalletActionsButton = styled.div`
    width: 160px;
    height: 30px;
    border-color: #38EF7D;
    border-radius: 16px;
    border-width: 0.4px;
    border-style: solid;
    padding: 10px; 
    display: flex;
    justify-content: center;
    align-items: center; 
    color: #FFFFFF;
    cursor: pointer;
    position: relative;

    .menu{
        width: 160px;
        height: ${({isPassportMenu})=> isPassportMenu ? '30px' : '90px'};
        border-radius: 10px;
        background: #43454490;
        backdrop-filter: blur(0.3rem);
        position: absolute;
        top: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: transform 0.2s ease-out;
        transform: scaleY(0);
        transform-origin: top;
    }

    .menu.expanded {        
        transform: scaleY(1);
        z-index: 109999;
    }

    .menu-option {
        height: 33px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 10px;
        cursor: pointer;        
    }
        
    .menu-option:hover {
        background: #1C1C1C;        
    }

    .destructive {
        color: #e3645b !important;
    }
`;
export default function WalletActionsButton({walletId}){

    const metamaskWanrningModalRef = useRef();
    const disconnectWanrningModalRef = useRef();
    const disconnectResultModalRef = useRef();

    const walletContext = useContext(Context);

    const [isExpanded, setIsExpanded] = useState(false);

    const isPassportMenu = walletContext.state.userWallet.wallets.passport_address == walletId;
    
    const isMetamaskWallet = walletContext.state.userWallet.wallets.metamask.includes(walletId);

    async function onSetWalletActionDefault(action = "qorbiWallet"){
        try {
            await setWalletActionDefault(walletId, action);
            var payload = walletContext.state.userWallet;            
            if(action == "qorbiWallet"){                
                payload.qorbiWallet = walletId;            
                walletContext.updateWallets(payload);
            }else{
                if(isMetamaskWallet){
                    metamaskWanrningModalRef.current.open();
                }else{
                    setMintingWallet();         
                }
            }
        } catch (error) {
            console.log(`Error updating wallets ${error}`);
        }
    }

    function setMintingWallet(){
        metamaskWanrningModalRef.current.close();
        var payload = walletContext.state.userWallet;
        payload.mintingWallet = walletId;
        walletContext.updateWallets(payload);
    }

    async function onDisconnectWallet(){        
        disconnectWanrningModalRef.current.open();
        try {
            var response = await disconnectWallet(walletId);
            disconnectWanrningModalRef.current.close();
            console.log(response);
            if(response.status == 200 && response.data != ""){
                var metamaskWallets = walletContext.state.userWallet.wallets.metamask;                
                metamaskWallets = metamaskWallets.filter((wallet)=>wallet != walletId);
                var payload = walletContext.state.userWallet;
                payload.wallets.metamask = metamaskWallets;
                console.log(payload)
                walletContext.updateWallets(payload);
            }else{
                disconnectResultModalRef.current.open();
            }
        } catch (error) {
            console.log(`Error updating wallets ${error}`);
        }
    }

    return (<StyledWalletActionsButton onClick={()=>setIsExpanded(!isExpanded)} isPassportMenu={isPassportMenu}>
        <ChangeWalletModal ref={metamaskWanrningModalRef} onConfirm={setMintingWallet} title='Non-Passport' description='Non-Passport wallets require gas to make transfers, are you sure you want to select this wallet as the Minting Wallet?'></ChangeWalletModal>
        <ChangeWalletModal ref={disconnectWanrningModalRef} onConfirm={onDisconnectWallet} title="Are You Sure?" description="Are you sure you want to disconnect this wallet?"></ChangeWalletModal>
        <ChangeWalletModal ref={disconnectResultModalRef} title="Something Went Wrong" description="We couldn't disconnect the wallet, make sure it is not your Qorbi Wallet or Minting Wallet."></ChangeWalletModal>
        Actions
        {
            isExpanded && (
                <div className={`menu ${isExpanded ? 'expanded' : ''}`}>
                    {
                        !isPassportMenu &&
                        <div className="menu-option" onClick={()=>onSetWalletActionDefault()}>Set QORBI Default</div>            
                    }
                    <div className="menu-option" onClick={()=>onSetWalletActionDefault("mintingWallet")}>Set NFT Minting Default</div>
                    {
                        !isPassportMenu &&
                        <div className="menu-option destructive" onClick={()=>disconnectWanrningModalRef.current.open()}>Disconnect</div>
                    }
                </div>
            )
        }
        
    </StyledWalletActionsButton>);
}