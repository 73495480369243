/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import * as animationData from "../assets/lotties/Dot_Loading.json";
import Lottie from "react-lottie";
import { Button } from "../components/Button";
import { ERC721TokenType, Link } from "@imtbl/imx-sdk";
import {
  environment,
  linkAddress,
  Toast,
  Ethereum,
  ImmutableX,
} from "../utils/Global";
import { assetsPlaceholder } from "../utils/AssetsPlaceholder";
import defaultImage from "../assets/img/PlaceholderNft.png";
import { Context } from "../context/WalletContext";
import Popup from "../components/Popup";
import TreasureButton from "../assets/svg/treasureButton";
import Close from "../assets/svg/Close";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";
import { useNavigate } from "react-router-dom";
import ErrorShowing from "../components/ErrorShowing";
import AssetCard from "../components/AssetCard";
import FiltersModal from "../components/FiltersModal";
import styled from "styled-components";
import WalletSelector from "../components/WalletSelector";

const immutableService = require("../context/ImmutableService");
const qorbiService = require("../context/QorbiService");
const zkevmService = require("../context/ZkevmService");

const StyledAssetsPage = styled.div`
  .tags {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    z-index: 9;
    flex-wrap: wrap;
    width: 100%;

    .floatingassets-btn {
      border: 0;
      padding: 5px;
      background: #38ef7d80;
      backdrop-filter: blur(30px);
      border-radius: 10px !important;
      margin-bottom: 5px;
      color: #ffffff;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .empty-asset-container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 26px;
    font-weight: 600;
  }

  #assets-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 5%;

    .mobile-controls {
      width: 100%;
      height: 12%;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 10000;
      margin-top: 5%;
    }

    .controls {
      width: 100%;
      height: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 10000;
    }

    #assets-page-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 2%;
      padding: 0px 22px 0px 22px;
    }
  }

  .switch-button {
    width: 20%;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .filter-button {
    border-radius: 100px;
    background-color: #2e2e2ea6;
    width: 2.7rem;
    height: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    backdrop-filter: blur(10px);
  }

  .asset-toggler {
    width: 152px;
    height: 2.7rem;
    background-color: #2e2e2ea6;
    border-radius: 100px;
    margin-left: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.26;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #9aa7ac;
    cursor: pointer;
    backdrop-filter: blur(10px);
  }

  .asset-toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-height: 53px;
    overflow: hidden;
  }

  @media (width <= 800px) {
    margin-top: 80px;

    .tags {
      flex-wrap: wrap;
    }

    .controls {
      display: none !important;
    }

    .mobile-controls {
      display: flex !important;
    }

    #assets-page-content {
      margin-top: 34% !important;
      justify-content: space-evenly !important;
      align-items: center !important;
      width: 100% !important;
    }

    .switch-button {
      width: 100% !important;
      justify-content: center !important;
      margin-bottom: 10px;
    }

    .wallet-selector {
      width: 100%;
    }

    .empty-asset-container {
      width: 70%;
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #ffffff;
      font-size: 26px;
      font-weight: 600;
    }

    .asset-toggler {
      margin-left: 0px !important;
      margin-right: 10px !important;
    }
  }
`;

const nftsCollections = [
  "0x2d59c7b8d253b826cdba9389be397a68af88cf08",
  "0x6cc712a73cb45d212f594ef942385ef90141dac0",
  "0x938b5c1850106ebc82d49af65a030e1d9083f2f5",
  "0xeea2fe42cc1774a802edfc7a08a35c361827befc",
  "0x8a2315e441786e10ff1962387407628108b9ba33",
];
const zkevmCollections = [
  "0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D", //Qorb
  "0xcd05E79857c1c0Eb1EeAf1573a12C25D411E0757", //qorX
  "0x2E1dac6efc5fA1a145112A918f422aa2AcEA6871", //Badge
  "0x7a6d3FB14A2eEFD8173584a96ABD2fAFA08e396a", //Pets
  "0xB1dBf0fC711F268B3a3755f8171a9269d6d105FD", //Skins
];

const MyAssets = () => {
  const navigate = useNavigate();
  const [currentAssets, setCurrentAssets] = useState({
    IMX: [],
    ZkEvm: [],
  });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [migratePopup, setMigratePopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [showLootboxButton, setShowLootboxButton] = useState(false);
  const [showKitchen, setShowKitchen] = useState(false);
  const [imxPass, setImxPass] = useState([]);
  const [zkevmPass, setZkevmPass] = useState([]);
  const [activeTab, setActiveTab] = useState("IMX");

  const { validateEggTrade, transferJaguar, state } = useContext(Context);
  const { devicedUsed, qorbiUserData } = useSphereOneProvider();
  const link = new Link(linkAddress);
  const changeWalletModal = useRef();
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filter, setFilter] = useState(["all"]);
  const loadingOpt = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleLootBoxes = (skinAssets) => {
    let lootBoxes = skinAssets.filter(
      (skin) =>
        [
          "0x8a2315e441786e10ff1962387407628108b9ba33",
          "0x624ded48d23337787212f975157dc477e20b1334",
        ].includes(skin.token_address) &&
        skin?.name?.startsWith("One Tap Loot Box")
    );
    if (lootBoxes.length > 0) {
      setShowLootboxButton(true);
      const lootBoxesJSON = JSON.stringify(lootBoxes);
      localStorage.setItem("lootboxes", lootBoxesJSON);
    }
  };
  const sendPetForTrade = async (eggsList) => {
    const send = async () => {
      const addressToSendPet = state.userWallet.string;
      let res2 = await transferJaguar(eggsList, addressToSendPet);
      if (res2.success) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        Toast.set({
          show: true,
          desc: "Congratulations! Your pet has been transferred to your wallet.",
          type: "success",
        });
      }
      return res2.success;
    };
    setTimeout(async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      let time1 = await send();
      if (!time1) {
        let time2 = await send();
        if (!time2) {
          let time3 = await send();
          if (!time3) {
            Toast.set({
              show: true,
              desc: "Something went wrong. Please contact us at info@qorgaming.com ",
              type: "error",
            });
          }
        }
      }
    }, 5000);
  };
  const zkevmassets = () => {
    const filteredItems = currentAssets["ZkEvm"].filter(
      (item) =>
        item.metadata?.name === "Legendary Loot Box" &&
        item.token?.name === "One Tap Weapon Skins Color Series"
    );
    localStorage.setItem("lootboxesZK", JSON.stringify(filteredItems));
    navigate("/openlootbox");
  };
  const verify = async () => {
    setShowPopup(false);
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(currentAssets[activeTab][element].token_id);
      nfts.push({
        type: ERC721TokenType.ERC721,
        tokenId: currentAssets[activeTab][element].token_id,
        tokenAddress: currentAssets[activeTab][element].token_address,
        toAddress:
          environment === "dev"
            ? "0x5B7Fc9dECd64aA393E9234a6D8cd92391199cb79"
            : "0x0C42E145B9dc6396928f8b3f6731FCC4c5D7A176",
      });
    });
    const arrayOfArraysOfNumbers = array.map((str) => [parseInt(str, 10)]);
    let res = await validateEggTrade(arrayOfArraysOfNumbers);
    if (res.success) {
      await link.setup({
        providerPreference: "none",
      });
      let response = await link.batchNftTransfer(nfts);
      if (response.result.length > 0) {
        sendPetForTrade(arrayOfArraysOfNumbers);
      }
    }
  };
  const getActiveQorX = async () => {
    const activeQorX = await qorbiService.getActivePass();
    if (activeQorX?.hasActivePass) {
      activeQorX.qorXPass.forEach((element) => {
        switch (element.chain) {
          case "immutablex":
            setImxPass((prevState) => [...prevState, element.qorXNFTID]);
            break;
          case "zkevm":
            setZkevmPass((prevState) => [...prevState, element.qorXNFTID]);
            break;

          default:
            break;
        }
      });
      // setActiveOLQor(data.qorXPass.qorXNFTID)
    }
  };

  const transferItemsToAddress = async (destinationAddress) => {
    setShowTransferPopup(false);
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(currentAssets[activeTab][element].token_id);
      nfts.push({
        type: ERC721TokenType.ERC721,
        tokenId: currentAssets[activeTab][element].token_id,
        tokenAddress: currentAssets[activeTab][element].token_address,
        toAddress: destinationAddress,
      });
    });
    if (
      state?.user?.authType === Ethereum ||
      state?.user?.authType === ImmutableX
    ) {
      await link.setup({
        providerPreference: "none",
      });
      let response = await link.batchNftTransfer(nfts);
      if (response.result.length > 0) {
        Toast.set({
          show: true,
          desc: "Congratulations! Your assets have been transfered.",
          type: "success",
        });
      }
    }
  };

  const startZkevmMigration = async () => {
    setShowTransferPopup(false);
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(currentAssets[activeTab][element].token_id);
      nfts.push({
        type: ERC721TokenType.ERC721,
        tokenId: currentAssets[activeTab][element].token_id,
        tokenAddress: currentAssets[activeTab][element].token_address,
        toAddress: "0x0000000000000000000000000000000000000000",
      });
    });
    if (
      state?.user?.authType === Ethereum ||
      state?.user?.authType === ImmutableX
    ) {
      await link.setup({
        providerPreference: "none",
      });
      let response = await link.batchNftTransfer(nfts);
      if (response.result.length > 0) {
        setMigratePopup(false);
        Toast.set({
          show: true,
          desc: "The migration has started! Please wait a moment",
          type: "success",
        });
        const migrationPayload = {
          tokenIdsBurned: array,
        };
        const migration = await qorbiService.migrateAssetsFromImmutable(
          migrationPayload
        );
        const pollMigrationStatus = async (followId) => {
          while (true) {
            const status = await qorbiService.trackImmutableMigration(followId);
            if (!status.pending) {
              Toast.set({
                show: true,
                desc: "The migration has been completed",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 5000);
              break;
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }
        };
        await pollMigrationStatus(migration.followId);
      }
    }
  };

  const handlePinPopup = async (transaction) => {
    switch (transaction) {
      case "trade":
        setShowPopup(true);
        break;
      case "transfer":
        setShowTransferPopup(true);
        break;
      case "migrate":
        setMigratePopup(true);
        break;
      default:
        console.error("Unknown option");
    }
  };
  const updateAssetsByKey = (key, newValues) => {
    setCurrentAssets((prevAssets) => ({
      ...prevAssets,
      [key]: newValues,
    }));
  };

  useEffect(() => {
    localStorage.removeItem("unauthorizedPath");
    localStorage.removeItem("lootboxes");
    localStorage.removeItem("lootboxesZK");

    const updateAssets = (newAssets) => {
      setCurrentAssets((prevAssets) => {
        const updatedIMXAssets = [...prevAssets.IMX, ...newAssets];
        handleLootBoxes(updatedIMXAssets);
        const eggAssets = updatedIMXAssets.filter(
          (item) => item.metadata?.class === "Pet Egg"
        );
        setShowKitchen(eggAssets.length > 0);
        return {
          ...prevAssets,
          IMX: updatedIMXAssets,
        };
      });
    };
    const updateZkEvmAssets = (newAssets) => {
      setCurrentAssets((prevAssets) => {
        const updatedZkEvmAssets = [...prevAssets.ZkEvm, ...newAssets];
        return {
          ...prevAssets,
          ZkEvm: updatedZkEvmAssets,
        };
      });
    };
    const fetchAllAssets = async (walletAddress) => {
      immutableService.getAssetsFromCollections(
        walletAddress,
        nftsCollections,
        updateAssets
      );
      zkevmService.getAssetsFromCollections(
        walletAddress,
        zkevmCollections,
        updateZkEvmAssets
      );
      // zkevmService.getAssetsFromCollections('0x3c0aa3581bf93abe632a1ce45273904e5d1d3135', zkevmCollections, updateZkEvmAssets)
    };
    if (currentAssets[activeTab].length === 0) {
      if (state?.user?.authType) {
      } else {
        setShowConnectMessage(true);
        updateAssetsByKey("IMX", assetsPlaceholder);
      }
      const addressLocalStorage = state?.userWallet?.string;
      if (addressLocalStorage !== undefined && addressLocalStorage !== null) {
        getActiveQorX();
        fetchAllAssets(state.user.data).then(() => setLoading(false));
      }
      setLoading(false);
    }
  }, [state]);

  function isAnXPass(item) {
    return (
      item?.collection?.address ===
        "0x6cc712a73cb45d212f594ef942385ef90141dac0" ||
      item?.collection?.address === "0xcd05E79857c1c0Eb1EeAf1573a12C25D411E0757"
    );
  }

  function onQorxAssetsTap() {
    let qorxAssets = currentAssets[activeTab].filter(
      (item) =>
        state?.user?.authType &&
        (item?.token_address === "0x6cc712a73cb45d212f594ef942385ef90141dac0" ||
          item?.token?.address === "0xcd05E79857c1c0Eb1EeAf1573a12C25D411E0757")
    );
    if (qorxAssets.length > 0) {
      if (
        (imxPass.length > 0 && activeTab === "IMX") ||
        (zkevmPass.length > 0 && activeTab === "ZkEvm")
      ) {
        navigate(`/qorx?source=${activeTab}`);
      }
    }
  }

  function onQorbAssetsTap(tokenId) {
    if (state.user == null || state.user === undefined) {
      return;
    }
    let qorbAssets = currentAssets[activeTab].filter(
      (item) =>
        item?.token_address === "0x2d59c7b8d253b826cdba9389be397a68af88cf08" ||
        (item?.token?.address ===
          "0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D" &&
          item.metadata.name !== "Legendary Loot Box" &&
          item.metadata.name !== "One Tap Operative Mystery Box" &&
          item.metadata.name !== "One Qorbi Burn") ||
        item?.metadata?.name === "Qorb"
    );
    if (qorbAssets.length > 0) {
      navigate(
        `/qorb-staking?qorb=${tokenId}&wallet=${state.user.data}&source=${activeTab}`
      );
    }
  }

  function showActivatePassButton(item) {
    if (!isAnXPass(item)) {
      return false;
    }
    if (activeTab === "IMX") {
      if (imxPass.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (activeTab === "ZkEvm") {
      if (zkevmPass.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    setSelected([]);
  }, [activeTab]);

  return (
    <StyledAssetsPage>
      {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
      {loading && (
        <div className="loading-container">
          <Lottie options={loadingOpt} height={90} width={88} />
          {state.loadingText ? <div className="loading-text"></div> : null}
        </div>
      )}
      <div
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
          display: "flex",
          alignItems: "flex-end",
          zIndex: 9999,
        }}
      >
        {errorMsg && <ErrorShowing errorText={errorMsg} />}
        {errorMsg && (
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "green",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => {
              setErrorMsg(null);
            }}
          >
            <Close />
          </div>
        )}
        {(showLootboxButton || activeTab === "ZkEvm") && (
          <div
            style={{
              width: "50px",
              height: "50px",
              filter: "drop-shadow(0 0 10px rgba(0, 255, 64, 0.8))",
              background: "green",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => {
              if (activeTab === "ZkEvm") {
                zkevmassets();
              } else {
                navigate("/openlootbox");
              }
            }}
          >
            <TreasureButton></TreasureButton>
          </div>
        )}
      </div>
      <FiltersModal
        isOpen={showFiltersModal}
        onClose={() => setShowFiltersModal(false)}
        selectedFilters={filter}
        setFilter={setFilter}
      ></FiltersModal>
      {showPopup && (
        <Popup
          onClose={() => setShowPopup(false)}
          title="Confirmation required"
          content={
            <>
              <p>
                Are you sure you want to trade your eggs for a pet? You cannot
                reverse this transaction.
              </p>
              <Button label="Yes, Please" onClick={() => verify()} />
              <span style={{ width: 10, display: "inline-block" }} />
              <Button
                transparent
                label="No"
                onClick={() => setShowPopup(false)}
              />
            </>
          }
        />
      )}
      {showTransferPopup ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              background: "black",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <p style={{ fontSize: "24px", color: "white" }}>
              Confirmation Required
            </p>
            <p style={{ fontSize: "16px", color: "grey" }}>
              Are you sure you want to transfer your assets? If so, please input
              the recipient's address.
            </p>
            <input
              style={{
                width: "100%",
                padding: "10px",
                margin: "10px 0",
                borderRadius: "5px",
              }}
              type="text"
              placeholder="Recipient's Address"
              value={destinationAddress}
              onChange={(e) => setDestinationAddress(e.target.value)}
            />
            <Button
              label="Yes, Please"
              onClick={() => {
                if (destinationAddress.trim() !== "") {
                  transferItemsToAddress(destinationAddress);
                } else {
                  alert("Please enter the recipient's address.");
                }
              }}
            />
            <span style={{ width: 10, display: "inline-block" }} />
            <Button
              transparent
              label="No"
              onClick={() => setShowTransferPopup(false)}
            />
          </div>
        </div>
      ) : null}
      {migratePopup ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              background: "black",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <p style={{ fontSize: "24px", color: "white" }}>
              Confirmation Required
            </p>
            <p style={{ fontSize: "16px", color: "grey" }}>
              Are you sure you want to migrate your assets to ZKEVM? This cannot
              be undone.
            </p>
            <Button
              label="Yes, Please"
              onClick={() => {
                startZkevmMigration(destinationAddress);
              }}
            />
            <span style={{ width: 10, display: "inline-block" }} />
            <Button
              transparent
              label="No"
              onClick={() => setMigratePopup(false)}
            />
          </div>
        </div>
      ) : null}
      <div id="assets-page-container">
        <div className="controls">
          <div className="mobile-wallet">
            <WalletSelector
              selectedWallet={activeTab}
              onWalletChange={setActiveTab}
            ></WalletSelector>
            <div className="tags">
              {showKitchen && (
                <button
                  className="floatingassets-btn"
                  onClick={() => navigate("/kitchen")}
                >
                  Burn Eggs
                </button>
              )}
              {selected.length > 0 &&
                selected.every(
                  (asset) =>
                    currentAssets[activeTab][asset]?.token_address ===
                    "0xeea2fe42cc1774a802edfc7a08a35c361827befc"
                ) && (
                  <button
                    className="floatingassets-btn"
                    onClick={() => handlePinPopup("trade")}
                  >
                    Trade For Pet
                  </button>
                )}
              {selected.length > 0 &&
                (state?.user?.authType === Ethereum ||
                  state?.user?.authType === ImmutableX) && (
                  <div>
                    <button
                      className="floatingassets-btn"
                      onClick={() => handlePinPopup("transfer")}
                    >
                      Transfer Assets
                    </button>
                    <button
                      className="floatingassets-btn"
                      onClick={() => handlePinPopup("migrate")}
                    >
                      Migrate Assets
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="mobile-controls">
          <WalletSelector
            selectedWallet={activeTab}
            onWalletChange={setActiveTab}
          ></WalletSelector>
          <div className="tags">
            {showKitchen && (
              <button
                className="floatingassets-btn"
                onClick={() => navigate("/kitchen")}
              >
                Burn Eggs
              </button>
            )}
            {selected.length > 0 &&
              selected.every(
                (asset) =>
                  currentAssets[activeTab][asset]?.token_address ===
                  "0xeea2fe42cc1774a802edfc7a08a35c361827befc"
              ) && (
                <button
                  className="floatingassets-btn"
                  onClick={() => handlePinPopup("trade")}
                >
                  Trade For Pet
                </button>
              )}
            {selected.length > 0 &&
              (state?.user?.authType === Ethereum ||
                state?.user?.authType === ImmutableX) && (
                <button
                  className="floatingassets-btn"
                  onClick={() => handlePinPopup("transfer")}
                >
                  Transfer Assets
                </button>
              )}
          </div>
        </div>
        <div id="assets-page-content">
          {currentAssets[activeTab].length > 0 ? (
            currentAssets[activeTab].map((item, index) => (
              <AssetCard
                index={index}
                activeTab={activeTab}
                selected={selected}
                setSelected={setSelected}
                defaultImage={defaultImage}
                item={item}
                devicedUsed={devicedUsed}
                state={state}
                imxPass={imxPass}
                zkevmPass={zkevmPass}
                qorbiUserData={qorbiUserData}
                setErrorMsg={setErrorMsg}
                showActivatePassButton={() => showActivatePassButton(item)}
                onPledgingTap={onQorxAssetsTap}
                onQorbPledgingTap={() =>
                  onQorbAssetsTap(item.token_id || item.id)
                }
              ></AssetCard>
            ))
          ) : (
            <div className="empty-asset-container">
              There are no assets for {activeTab} in your wallet
            </div>
          )}
        </div>
      </div>
    </StyledAssetsPage>
  );
};

export default MyAssets;
