import React, { useState } from 'react';
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const QorbCollection = () => {

    const navigate = useNavigate();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { qorbiUserData } = useSphereOneProvider();

    const [showMessage, setShowMessage] = useState(false);

    const addQorbWhitelist = async () => {
        const username = qorbiUserData.username;
        if (username !== undefined) {
            try {
                const response = await axios.post('https://api.qorbiworld.com/v1/add-qorb-whitetlist', {
                    username: username
                });
                setShowMessage(true);
                return response.data;
            } catch (error) {
                console.error('Error adding to mobile waitlist:', error);
                throw error;
            }
        } else {
            localStorage.setItem('unauthorizedPath', '/qorb-collection');
            navigate('/profile')
        }
    }
    return (
        <div className="darkbg">
            <section className='banner-header'            >
                <iframe
                    className='hide-iframe'
                    style={{ minWidth: "100%", minHeight: "100%", opacity: 0.9 }}
                    title="video"
                    src="https://customer-2bwi7pce1aev1tze.cloudflarestream.com/dae5c1ff80115f5eff0667ac8836ce1a/iframe?autoplay=1&loop=true&muted=true&controls=false"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                ></iframe>
                <div className='content-bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h1 className='banner-title'>QORB {isMobile && <br />}Collection</h1>
                            </div>
                            <div className='col-12'>
                                <h5 className='banner-sub'>
                                    Get access to purchase the premier Qorbi World Play-To-Earn collection. This collection gives you access to earn accross 3 different games, including One Tap and One Tap mobile. Get Early access for a 20% discount.
                                </h5>
                            </div>
                            <div className='col-12'>
                                <button
                                    className="glow-on-hover"
                                    onClick={() => {
                                        addQorbWhitelist()
                                    }}
                                    style={{ display: "block", margin: "30px auto" }}
                                >
                                    Whitelist Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section plan-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="green-title text-center">Why Purchase a Qorb</h2>
                            <p
                                style={{ fontSize: 18, marginBottom: 40 }}
                                className="text-center"
                            >
                                A uniquely designed 3D animated character. This one of a kind
                                art was inspired by the innocence of our young people and
                                their love for esports. Every Qorb has attributes that
                                represent their special features. The Qorbs are created by the
                                Qorbi World an Estonia Company team of concept designers, 3D Artist, 3D Animators,
                                and game designers.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <iframe
                                title="col-12 col-lg-6"
                                src="https://iframe.cloudflarestream.com/29761506bb8ee924685951d53f5960e8?muted=true&poster=https%3A%2F%2Fcloudflarestream.com%2F29761506bb8ee924685951d53f5960e8%2Fthumbnails%2Fthumbnail.jpg%3Ftime=7s%3D%26height%3D800"
                                style={{ height: "100%", width: "100%", minHeight: 200 }}
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowFullScreen={true}
                            ></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="cont">
                                <div className="price">
                                    <span className="symbol"></span>Qorbs Owner Benefits
                                    <span className="cents"></span>
                                </div>
                                <ul className="desc">
                                    <li>Exclusive Access to in-game items</li>
                                    <li>
                                        Only way to earn in Qorbi World is with a Qorbs Pro Pass
                                    </li>
                                    <li>Exclusive Games only for Pro Pass holders</li>
                                    <li>Pledging</li>
                                    <li>Voting & Earning</li>
                                    <li>Free in-game skins</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showMessage && ( // Render message
                    <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 9999, }}                    >
                        <div style={{ backgroundColor: "white", color: "#31E17F", padding: 20, borderRadius: 8, border: "2px solid #31E17F", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", display: "flex", flexDirection: "column", alignItems: "center", }}>
                            <p style={{ textTransform: "uppercase", color: "#1FB888" }}>CONGRATULATIONS, YOU HAVE BEEN WHITELISTED FOR THE QORB COLLECTION</p>
                            <button
                                onClick={() => setShowMessage(false)}
                                style={{ backgroundColor: "#1FB888", border: "none", color: "white", padding: "10px 24px", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "8px", cursor: "pointer", marginTop: 10, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", alignSelf: "center", }}
                            >
                                OK
                            </button>
                        </div>

                    </div>
                )
            }
        </div >

    );
};

export default QorbCollection;
