import axios from "axios";

const nameToCollectionMap = {
  "QorbiWorld Pets": "pet",
  "One Tap Badges": "badge",
  "Qor-X Passes": "pass",
  "Qorb Collection": "qorb",
  "One Tap Weapon Skins Color Series": "skin",
};

export const getAssetsFromCollections = async (
  user,
  collections,
  updateAssets
) => {
  for (const collection of collections) {
    const baseUrl = `https://immutable-mainnet.blockscout.com/api/v2/tokens/${collection}/instances?holder_address_hash=${user}`;
    let unique_token = null;
    while (true) {
      let url = unique_token ? baseUrl + unique_token : baseUrl;
      const response = await axios.get(url);
      const nftMetadataPromises = response.data.items
        .filter((token) => nameToCollectionMap[token.token.name])
        .map(async (token) => {
          const collection = nameToCollectionMap[token.token.name];
          const tokenId = token.id;
          try {
            const metadataResponse = await fetch(
              `https://assets.qorbiworld.com/resource/${collection}/${tokenId}`
            );
            if (!metadataResponse.ok) {
              throw new Error(
                `Error fetching metadata for token ${tokenId}: ${metadataResponse.statusText}`
              );
            }
            const metadata = await metadataResponse.json();
            return {
              ...token,
              metadata,
            };
          } catch (error) {
            console.error(
              `Error fetching metadata for token ${tokenId} in collection ${collection}:`,
              error
            );
            return null;
          }
        });
      const nftsWithMetadata = (await Promise.all(nftMetadataPromises)).filter(
        Boolean
      );
      nftsWithMetadata.forEach((asset) => {
        asset.collection = {
          address: collection,
        };
        return asset;
      });
      updateAssets(nftsWithMetadata);
      if (response.data.next_page_params) {
        unique_token = `&unique_token=${response.data.next_page_params.unique_token}`;
      } else {
        break;
      }
    }
  }
};
