import React, { useState, useContext } from "react";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import { Context } from "../context/WalletContext";
import { ImmutablePassport } from "../utils/Global";
import OTPInput from "./OTP";
import { passportInstance } from "../context/PassportService";

const qorbiServices = require("../context/QorbiService");

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  // const [referralCode, setReferralCode] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { logOut, state } = useContext(Context);
  const { setRegisterUser, setStartMission, setQorbiUserData, qorbiUserData } =
    useSphereOneProvider();

  const handleChangeUsername = (e) => {
    const value = e.target.value;
    if (!/\s/.test(value)) {
      setUsername(value);
    }
  };

  const handleChangeEmail = (e) => {
    const value = e.target.value;
    if (!/\s/.test(value)) {
      setEmail(value);
    }
  };

  // const handleChangeReferralCode = (e) => {
  //     const value = e.target.value;
  //     if (!/\s/.test(value)) {
  //         setReferralCode(value);
  //     }
  // };
  const logoutService = async () => {
    const currentSession = localStorage.getItem("userInfo");
    const infoFromString = JSON.parse(currentSession);
    localStorage.removeItem("userInfo");
    try {
      if (infoFromString.authType === "ImmutablePassport") {
        await passportInstance.logout();
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleCloseOtpSubmit = () => {
    setShowOTP(false);
  };
  const handleOtpSubmit = async (otpCode) => {
    setShowOTP(false);
    try {
      await qorbiServices.createQorbiEmail(email, otpCode);
      if (qorbiUserData.username.startsWith("not-username-")) {
        await qorbiServices.createQorbiUsername(username);
        setQorbiUserData((prevState) => ({
          ...prevState,
          username: username,
          email: email,
        }));
      }
      afterRegister();
    } catch (error) {
      console.error(error);
    }
  };

  const afterRegister = async () => {
    setRegisterUser(false);
    setStartMission(true);
    const gameTracker = localStorage.getItem("oneTapTrackId");
    if (gameTracker) {
      await qorbiServices.authUnity(gameTracker);
      localStorage.removeItem("oneTapTrackId");
    }
  };
  const handleSubmit = async (event) => {
    console.log(qorbiUserData);
    event.preventDefault();
    if (
      (!username && qorbiUserData.username.startsWith("not-username-")) ||
      (!email &&
        (state?.user?.authType !== ImmutablePassport ||
          qorbiUserData.email.startsWith("not-email-")))
    ) {
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    const addUser = async () => {
      try {
        if (qorbiUserData.email.startsWith("not-email-")) {
          await qorbiServices.verifyEmail(email);
          setShowOTP(true);
        } else if (qorbiUserData.username.startsWith("not-username-")) {
          await qorbiServices.createQorbiUsername(username);
          setQorbiUserData((prevState) => ({
            ...prevState,
            username: username,
          }));
          afterRegister();
        }
      } catch (addUserError) {
        console.error("Error adding user:", addUserError);
        setErrorMessage(
          addUserError.response?.data?.message ||
            addUserError.message ||
            "An error occurred."
        );
      }
    };
    await addUser();
  };

  return (
    <div className="main-overlay">
      <div className="black-overlay" style={{ width: !isMobile && "30%" }}>
        <h2 style={{ color: "white" }}>Create Your Account</h2>
        {errorMessage && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {(state?.user?.authType === ImmutablePassport ||
            qorbiUserData?.username?.startsWith("not-username-")) && (
            <div className="register-form-group">
              <label
                htmlFor="username"
                style={{
                  color: "white",
                  display: "block",
                  textAlign: "left",
                  marginLeft: 10,
                }}
              >
                Username:
              </label>
              <input
                style={{ backgroundColor: "white" }}
                className="register-form-input"
                type="text"
                id="username"
                placeholder="Ex: CoolQorUser"
                value={username}
                onChange={handleChangeUsername}
                required
              />
            </div>
          )}
          {state?.user?.authType !== ImmutablePassport &&
            qorbiUserData?.email?.startsWith("not-email-") && (
              <div className="register-form-group">
                <label
                  htmlFor="email"
                  style={{
                    color: "white",
                    display: "block",
                    textAlign: "left",
                    marginLeft: 10,
                  }}
                >
                  Email:
                </label>
                <input
                  className="register-form-input"
                  type="email"
                  id="email"
                  placeholder="Ex: cool@qor.user"
                  value={email}
                  onChange={handleChangeEmail}
                  required
                />
              </div>
            )}
          {/* <div className="register-form-group">
                        <label htmlFor="referralCode" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Referral Code:</label>
                        <input
                            className='register-form-input'
                            type="referral"
                            id="referralCode"
                            placeholder="Optional"
                            value={referralCode}
                            onChange={handleChangeReferralCode}
                        // required
                        />
                    </div> */}
          <button className="register-form-button" type="submit">
            Register
          </button>
        </form>
        <button
          style={{
            marginTop: 15,
            backgroundColor: "red",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#B80000")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "red")}
          className="register-form-button"
          onClick={() => {
            logOut();
            logoutService();
          }}
        >
          Cancel Register
        </button>
      </div>
      {showOTP && (
        <OTPInput onSubmit={handleOtpSubmit} onCancel={handleCloseOtpSubmit} />
      )}
    </div>
  );
};

export default RegisterForm;
