import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

const StyledNFTDetailsPage = styled.div`
  margin-top: 10%;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (width <= 800px) {
    margin-top: 30%;
    flex-direction: column;
    padding: 0px;
  }
`;

const StyledAssetImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 800px) {
    width: 100%;
  }
`;

const StyledAssetImage = styled.div`
  height: 350px;
  width: 600px;
  border-radius: 40px;
  background: linear-gradient(0.25turn, #ffffff00, #38ef7d, #ffffff00);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    object-fit: cover;
    height: 348px;
    width: 598px;
    border-radius: 40px;
    object-fit: cover;
  }

  @media (width <= 800px) {
    width: 80vw;
    height: 200px;

    img {
      width: 80vw;
      height: 198px;
    }
  }
`;

const StyledNFTDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 800px) {
    width: 100%;
    padding: 0px 15px 0px 15px;
  }
`;

const StyledNFTNameContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 700px;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-bottom: 10px;

  @media (width <= 800px) {
    justify-content: center;
    width: 100%;
  }
`;

const StyledNFTDetails = styled.div`
  width: 700px;
  height: 50px;
  background: #1a1919 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0d;
  border-radius: 45px;
  opacity: 1;
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
  align-items: center;
  margin-bottom: 20px;

  @media (width <= 800px) {
    width: 100%;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
  }
`;

const StyledTableHeader = styled.th`
  width: 61px;
  height: 21px;
  text-align: left;
  font-weight: medium;
  letter-spacing: 0px;
  color: #38ef7d;
  text-transform: capitalize;
  opacity: 1;
  padding: 10px;
`;

const StyledAllDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 700px;

  @media (width <= 800px) {
    width: 100%;
  }
`;

const StyledDetailContainer = styled.div`
  background: #1a1919;
  border: 1px solid #ffffff0d;
  border-radius: 14px;
  flex: 1 0 auto;
  height: 60px;
  display: flex;
  padding: 5px 5px 5px 15px;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
`;

const StyledTabOption = styled.button`
  text-align: left;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0px;
  color: ${({ isSelected }) => (isSelected ? "#FFFFFF" : "#a6a6a6")};
  opacity: 1;
  border: none;
  border-width: 0px;
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
`;

const NftDetails = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { collection, token_id } = useParams();
  const source = urlParams.get("source");
  const [nftData, setNftData] = useState(null);
  const [nftTransactions, setNftTransactions] = useState(null);
  const [activeTab, setActiveTab] = useState("details");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const collectionAddress =
    collection === "egg"
      ? "0xeea2fe42cc1774a802edfc7a08a35c361827befc"
      : collection === "skin"
        ? "0x8a2315e441786e10ff1962387407628108b9ba33"
        : collection === "qorb"
          ? "0x2d59c7b8d253b826cdba9389be397a68af88cf08"
          : collection === "badge"
            ? "0x938b5c1850106ebc82d49af65a030e1d9083f2f5"
            : collection === "X"
              ? "0x6cc712a73cb45d212f594ef942385ef90141dac0"
              : "unknown";
  const getItemDetails = async () => {
    if (source !== "zkevm") {
      const apiUrl = `https://api.x.immutable.com/v1/assets/${collectionAddress}/${token_id}`;
      await axios
        .get(apiUrl)
        .then((response) => {
          setNftData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const nameToCollectionMap = {
        "QorbiWorld Pets": "pet",
        "One Tap Badges": "badge",
        "Qor-X Passes": "pass",
        "Qorb Collection": "qorb",
        "One Tap Weapon Skins Color Series": "skin",
      };
      const apiUrl = `https://immutable-mainnet.blockscout.com/api/v2/tokens/${collection}/instances/${token_id}`;
      const zkToken = await axios.get(apiUrl);
      await axios
        .get(
          `https://assets.qorbiworld.com/resource/${nameToCollectionMap[zkToken.data.token.name]
          }/${zkToken.data.id}`
        )
        .then((response) => {
          setNftData({
            ...zkToken.data,
            ...response.data,
          });
        });
    }
  };

  const fetchOrderDetails = async () => {
    const tradeResponse = await axios.get(
      `https://api.x.immutable.com/v3/trades?party_b_token_address=${collectionAddress}&party_b_token_id=${token_id}`
    );
    const tradeOrderDetails = await Promise.all(
      tradeResponse.data.result
        .filter((transaction) => transaction.a && transaction.b)
        .map(async (transaction) => {
          try {
            const orderDetailsResponseBuyer = await axios.get(
              `https://api.x.immutable.com/v3/orders/${transaction.a.order_id}`
            );
            const orderDetailsResponseSeller = await axios.get(
              `https://api.x.immutable.com/v3/orders/${transaction.b.order_id}`
            );

            return {
              timestamp: transaction.timestamp,
              a: orderDetailsResponseBuyer.data,
              b: orderDetailsResponseSeller.data,
            };
          } catch (error) {
            console.error("Error fetching order details:", error);
            return null;
          }
        })
    );

    return tradeOrderDetails.filter((orderDetails) => orderDetails !== null);
  };

  const getItemTransactions = async () => {
    try {
      const mintResponse = await axios.get(
        `https://api.x.immutable.com/v1/mints?order_by=created_at&direction=desc&token_id=${token_id}&token_address=${collectionAddress}`
      );
      const transferResponse = await axios.get(
        `https://api.x.immutable.com/v1/transfers?token_id=${token_id}&token_address=${collectionAddress}`
      );
      // Fetch order details for trade transactions
      const tradeOrderDetails = await fetchOrderDetails();

      // Combine the result arrays
      const combinedData = [
        ...mintResponse.data.result,
        ...transferResponse.data.result,
        ...tradeOrderDetails,
      ];

      // Sort the combined array based on date
      combinedData.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setNftTransactions(combinedData);
    } catch (error) {
      console.error(error);
    }
  };

  const capitalizeAndReplaceUnderscore = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
  };

  const sliceWallet = (str) => {
    if (!isMobile) {
      const firstPart = str.slice(0, 8);
      const lastPart = str.slice(-8);
      return `${firstPart}...${lastPart}`;
    } else {
      const firstPart = str.slice(0, 8);
      return `${firstPart}...`;
    }
  };

  const calculateTimeElapsed = (timestamp) => {
    const now = new Date();
    const transactionTime = new Date(timestamp);
    const timeDifference = now - transactionTime;

    // Calculate elapsed time in seconds, minutes, hours, and days to show in table
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };

  const tableHeaderStyle = {
    width: "61px",
    height: "21px",
    textAlign: "left",
    fontWeight: "medium",
    font: "normal normal medium 18px / 21px SF UI Display",
    letterSpacing: "0px",
    color: "#38EF7D",
    textTransform: "capitalize",
    opacity: "1",
  };

  const tableCellStyle = {
    width: "150px",
    height: "21px",
    padding: "10px",
    textAlign: "left",
    font: "normal normal medium 18px / 21px SF UI Display",
    letterSpacing: "0px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    opacity: "1",
    fontWeight: "medium",
  };

  const tableRowEvenStyle = {
    background: "#1E1E1E",
  };

  const tableRowOddStyle = {
    background: "#141414",
  };
  useEffect(() => {
    getItemDetails();
    getItemTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetailsTabContent = () => {
    // Combine metadata and attributes into a unified list of items
    const metadataItems = nftData.metadata
      ? Object.entries(nftData.metadata)
        .filter(
          ([key, value]) =>
            key !== "image_url" &&
            value !== null &&
            key !== "animation_url_mime_type" &&
            key !== "video_url" &&
            key !== "animation_url" &&
            key !== "description"
        )
        .map(([key, value]) => ({
          key,
          label: capitalizeAndReplaceUnderscore(key),
          value: value.toString(),
        }))
      : [];

    const attributesItems = nftData.attributes
      ? nftData.attributes.map(({ trait_type, value }) => ({
        key: trait_type,
        label: capitalizeAndReplaceUnderscore(trait_type),
        value: value ? value.toString() : "",
      }))
      : [];

    const detailsItems = [...metadataItems, ...attributesItems];

    return (
      <StyledAllDetailsContainer>
        {detailsItems.map(({ key, label, value }) => (
          <StyledDetailContainer key={key}>
            <strong
              style={{
                textAlign: "left",
                fontWeight: "bold",
                letterSpacing: "0px",
                color: "#A6A6A6",
                textTransform: "capitalize",
                opacity: "1",
                width: "100%",
                fontSize: "18px",
              }}
            >
              {label}:
            </strong>
            <div
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "bold",
                letterSpacing: "0px",
                color: "#FFFFFF",
                fontSize: "16px",
              }}
            >
              {value}
            </div>
          </StyledDetailContainer>
        ))}
      </StyledAllDetailsContainer>
    );
  };

  const renderTransactionsTabContent = () => (
    <div>
      {nftTransactions !== null && (
        <table
          style={{
            width: "100%",
            color: "white",
            borderCollapse: "collapse",
            borderRadius: "30px",
            overflow: "hidden",
            background: "#1a1919 0% 0% no-repeat padding-box",
          }}
        >
          <thead>
            <tr>
              <StyledTableHeader
                style={{ ...tableHeaderStyle, borderTopLeftRadius: "8px" }}
              >
                Time
              </StyledTableHeader>
              <StyledTableHeader style={tableHeaderStyle}>
                Type
              </StyledTableHeader>
              <StyledTableHeader style={tableHeaderStyle}>
                From
              </StyledTableHeader>
              <StyledTableHeader
                style={{ ...tableHeaderStyle, borderTopRightRadius: "8px" }}
              >
                To
              </StyledTableHeader>
            </tr>
          </thead>
          <tbody>
            {nftTransactions.map((transaction, index) => (
              <tr
                key={index}
                style={index % 2 === 0 ? tableRowEvenStyle : tableRowOddStyle}
              >
                <td
                  style={tableCellStyle}
                  title={new Date(transaction.timestamp).toLocaleString()}
                >
                  {calculateTimeElapsed(transaction.timestamp)}
                </td>
                <td style={tableCellStyle}>
                  {transaction.a && transaction.b
                    ? "Sell"
                    : transaction.receiver
                      ? "Transfer"
                      : "Mint"}
                </td>
                <td style={tableCellStyle}>
                  {transaction.a && transaction.b
                    ? sliceWallet(transaction.b.user)
                    : transaction.receiver
                      ? sliceWallet(transaction.user)
                      : "N/A"}
                </td>
                <td style={tableCellStyle}>
                  {transaction.a && transaction.b
                    ? sliceWallet(transaction.a.user)
                    : transaction.receiver
                      ? sliceWallet(transaction.receiver)
                      : sliceWallet(transaction.user)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  const renderPriceHistoryTabContent = () => {
    const tradeTransactions =
      nftTransactions?.filter(
        (transaction) => transaction.a && transaction.b
      ) || [];

    if (tradeTransactions.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75px",
            width: "100%",
            backgroundColor: "#11998E",
            color: "white",
            border: "5px",
            borderColor: "#fff",
            borderRadius: "100px",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        >
          This item has not been traded yet.
        </div>
      );
    }

    const averagePrice =
      tradeTransactions.length > 0
        ? tradeTransactions.reduce(
          (total, transaction) =>
            total + Number(transaction.a.amount_sold) / 1e18,
          0
        ) / tradeTransactions.length
        : 0;

    return (
      <div>
        <div style={{ marginBottom: "10px", color: "white" }}>
          <strong>Average Price:</strong> {averagePrice}/ETH
        </div>
        <table
          style={{
            width: "100%",
            maxWidth: "1200px",
            color: "white",
            borderCollapse: "collapse",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "#1bb08a",
                  padding: "10px",
                  borderBottom: "2px solid white",
                }}
              >
                Date
              </th>
              <th
                style={{
                  backgroundColor: "#1bb08a",
                  padding: "10px",
                  borderBottom: "2px solid white",
                }}
              >
                Buyer
              </th>
              <th
                style={{
                  backgroundColor: "#1bb08a",
                  padding: "10px",
                  borderBottom: "2px solid white",
                }}
              >
                Price/ETH
              </th>
            </tr>
          </thead>
          <tbody>
            {tradeTransactions.map((transaction, index) => (
              <tr key={index}>
                <td
                  style={{ padding: "10px", borderBottom: "1px solid white" }}
                  title={new Date(transaction.timestamp).toLocaleString()}
                >
                  {new Date(transaction.timestamp).toLocaleDateString()}
                </td>
                <td
                  style={{ padding: "10px", borderBottom: "1px solid white" }}
                >
                  {transaction.a.user}
                </td>
                <td
                  style={{ padding: "10px", borderBottom: "1px solid white" }}
                >
                  {Number(transaction.a.amount_sold) / 1e18}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "details":
        return renderDetailsTabContent();
      case "transactions":
        return renderTransactionsTabContent();
      case "priceHistory":
        return renderPriceHistoryTabContent();
      default:
        return null;
    }
  };

  return (
    <StyledNFTDetailsPage>
      {nftData !== null && (
        <>
          {/* Left Side: Image and Name */}
          <StyledAssetImageContainer>
            <StyledAssetImage>
              <img
                src={nftData.image_url || nftData.image}
                alt={nftData.name}
              />
            </StyledAssetImage>
          </StyledAssetImageContainer>
          {/* Right Side: Grid with NFT Details */}
          <StyledNFTDetailsContainer>
            <StyledNFTNameContainer>{nftData.name}</StyledNFTNameContainer>
            {/* Tab Menu */}
            <StyledNFTDetails>
              <StyledTabOption
                isSelected={activeTab === "details"}
                onClick={() => setActiveTab("details")}
              >
                Details
              </StyledTabOption>
              {source !== "zkevm" && (
                <StyledTabOption
                  isSelected={activeTab === "transactions"}
                  onClick={() => setActiveTab("transactions")}
                >
                  Transactions
                </StyledTabOption>
              )}
              {source !== "zkevm" && (
                <StyledTabOption
                  isSelected={activeTab === "priceHistory"}
                  onClick={() => setActiveTab("priceHistory")}
                >
                  Price History
                </StyledTabOption>
              )}
            </StyledNFTDetails>
            {/* Tab Content */}
            {renderTabContent()}
          </StyledNFTDetailsContainer>
        </>
      )}
    </StyledNFTDetailsPage>
  );
};

export default NftDetails;
