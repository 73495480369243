import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router";
import { Context } from "../context/WalletContext";
import { Toast } from "../utils/Global";

const qorbiService = require('../context/QorbiService');
const immutableServices = require('../context/ImmutableService');

const VotePage = () => {

    const { state } = useContext(Context);

    let navigate = useNavigate();

    const isQorbOwner = async () => {
        const isQorbOwner = await immutableServices.assetOwner(state.user.data, 'qorb')
        if (!(isQorbOwner)) {
            navigate(`/market`)
        }
    }

    useEffect(() => {
        isQorbOwner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [loading, setLoading] = useState(false);

    const handleVote = async (voteChoice) => {
        setLoading(true);
        try {
            await qorbiService.voteUsingQorb(voteChoice);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            Toast.set({
                show: true,
                desc: 'Success! Your vote has been counted!',
                type: 'success',
            });
        }
    };

    return (
        <div className="bundle-container just-center">
            <h1 className='home-replica-title'>Vote to convert EMBER into QORBI</h1>
            <p className="home-replica-subtitle">Your vote matters to shape the future of Qorbi World's economy.</p>
            <section className="how-it-works">
                <div>
                    <h2 className='section-title'>QORBI Token Integration Whitepaper</h2>
                    <p style={{ color: "#fff" }}>
                        <strong>Abstract:</strong> The proposal to transition from Ember to QORBI will unify the in-game currency
                        and implement a 5% burn mechanism to create a deflationary economy.
                    </p>
                    <h3 className='section-title' style={{ marginTop: 45 }}>Key Proposal Points:</h3>
                    <ul className="benefits-list row-to-column">
                        <li>Transition from Ember to QORBI for all in-game purchases.</li>
                        <li>A 5% burn will be implemented on all QORBI earnings.</li>
                        <li>Full QORBI adoption will occur in Q3 2025.</li>
                    </ul>
                </div>

                <div>
                    <h3 className='section-title' style={{ marginTop: 45 }}>Proposal Details:</h3>
                    <p className="qorb-pool-text row-to-column" style={{ flexDirection: 'column' }}>
                        <p>Phase 1: Announcement & Preparations (Q1 2025)</p>
                        <p>Phase 2: Dual Currency Mode (Q2 2025)</p>
                        <p>Phase 3: Full QORBI Adoption (Q3 2025)</p>
                    </p>
                </div>
            </section>

            <section className="faq">
                <h2 className="home-replica-subtitle">Your Vote</h2>
                <div className="faq-item">
                    <strong>
                        You currently are a QORB Owner. Cast your vote to approve or deny the QORBI burn system:
                    </strong>
                    <div>
                        <button
                            onClick={() => handleVote(true)}
                            disabled={loading}
                            className='greenBtn'
                        >
                            Approve
                        </button>
                        <button
                            onClick={() => handleVote(false)}
                            disabled={loading}
                            className='greenBtn'
                        >
                            Deny
                        </button>
                    </div>
                </div>
                <p>This vote helps us ensure the future of QORBI within the One Tap FPS ecosystem. Your feedback is invaluable.</p>
            </section>
            <a
                href="https://whitepaper.qorbiworld.com/qorbi-world/economy/usdqorbi-tokenomics/qorbi-token-integration-whitepaper-transition-from-ember-to-qorbi"
                target="_blank"
                rel="noopener noreferrer"
                className='whitepaper-link'
            >
                Read the full whitepaper here
            </a>
        </div>
    );
};

export default VotePage;
