import styled from "styled-components";
import axios from "axios";

const StyledAssetCard = styled.div`
  width: 20rem;
  height: 25rem;
  margin: 30px 5px 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: relative;

  & .asset-image {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ isSelected }) =>
      isSelected ? "#38EF7D" : "transparent"};
    object-fit: ${({ item }) =>
      item?.name?.startsWith("One Tap Loot Box") ? "contain" : ""};
  }

  & .radial-blur {
    position: absolute;
    height: 25rem;
    width: 20rem;
    background: transparent;
    backdrop-filter: blur(30px);
    border-radius: 40px;
    z-index: -1;
  }

  & .inner-radial-blur {
    position: absolute;
    bottom: 100px;
    z-index: -2;
    height: 130px;
    width: 260px;
    background: #38ef7d;
    border-color: black;
    border-style: solid;
    border-width: 40px;
    border-bottom-width: 0px;
  }

  & .owner-username-outer-container {
    display: flex;
    padding: 1px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#38ef7d70, #11998e70);
    border-radius: 14px;
  }

  & .owner-username-inner-container {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    border-radius: 14px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.58px;
    font-family: "exo";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .selected-indicator {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: ${({ isSelected }) =>
      isSelected ? "#38EF7D" : "transparent"};
  }

  @media (width <= 800px) {
    width: 10rem !important;
    height: 13rem !important;

    .selected-indicator {
      height: 20px;
      width: 20px;
      top: 10px;
      right: 10px;
    }

    & img {
      border-radius: 25px !important;
      margin-bottom: 15px;
    }

    .radial-blur {
      width: 8rem !important;
      height: 12rem !important;
    }

    .inner-radial-blur {
      height: 65px;
      width: 130px;
    }

    .owner-username-outer-container {
      width: 160px !important;
      max-height: 35px !important;
      text-align: start !important;
    }

    .owner-username-inner-container {
      width: 160px !important;
      max-height: 33px !important;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .active-pass {
    cursor: pointer;
    height: 30px;
    width: 120px;
    background: #38ef7d60;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 125px;
  }
`;

function AssetCard({
  index,
  activeTab,
  selected,
  setSelected,
  item,
  state,
  showActivatePassButton,
  qorbiUserData,
  setErrorMsg,
  onPledgingTap,
  onQorbPledgingTap,
  imxPass,
  zkevmPass,
}) {
  const openDetails = (adrs, token_id, status) => {
    if (activeTab === "IMX") {
      const itemId = token_id;
      if (status === "imx") {
        const collection =
          adrs === "0xeea2fe42cc1774a802edfc7a08a35c361827befc"
            ? "egg"
            : adrs === "0x8a2315e441786e10ff1962387407628108b9ba33"
            ? "skin"
            : adrs === "0x938b5c1850106ebc82d49af65a030e1d9083f2f5"
            ? "badge"
            : adrs === "0x6cc712a73cb45d212f594ef942385ef90141dac0"
            ? "X"
            : adrs === "0x2d59c7b8d253b826cdba9389be397a68af88cf08"
            ? "qorb"
            : "unknown";
        if (collection !== "unknown") {
          window.open(`/nft-details/${collection}/${itemId}`, "_blank");
        }
      } else if (status === "saakuru") {
        window.open(
          `https://saakuru.com/explorer/token/${adrs}/instance/${token_id}/token-transfers`,
          "_blank"
        );
      }
    } else {
      window.open(`/nft-details/${adrs}/${token_id}?source=zkevm`, "_blank");
    }
  };

  function getImageSource() {
    return (
      item.image_url ||
      item?.metadata?.image ||
      (item?.metadata?.name === "Qorb" ||
      item?.metadata?.name === "Qorb Premier Badge"
        ? item.extra?.imageUrl
        : item.metadata?.imageUrl) ||
      item.metadata?.imageUrl ||
      item.image
    );
  }

  const activateQorX = async (token_id) => {
    try {
      const apiUrl = `https://dev-api.qorbiworld.com/stake/stake-nft`;

      const { walletAddress, chain } = (() => {
        if (activeTab === "ZkEvm") {
          return {
            walletAddress: state.user.data,
            chain: "zkevm",
          };
        } else if (activeTab === "IMX") {
          return {
            walletAddress: state.user.data,
            chain: "immutablex",
          };
        }
      })();
      const requestBody = {
        username: qorbiUserData.username,
        email: qorbiUserData.email,
        walletAddress,
        QorXNftID: token_id,
        chain,
      };

      axios
        .post(apiUrl, requestBody)
        .then((response) => {
          setErrorMsg("Qor-X Pass Activated");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg(
            "QORX not activated. Try again, or contact us in Discord"
          );
        });
    } catch (error) {
      setErrorMsg(error);
    }
  };

  function onAssetSelect(e) {
    e.stopPropagation();
    if (activeTab === "IMX") {
      if (selected.includes(index)) {
        const indx = selected.indexOf(index);
        const clone = [...selected];
        clone.splice(indx, 1);
        setSelected(clone);
      } else {
        setSelected([...selected, index]);
      }
    }
  }

  function onAssetCardTap() {
    openDetails(
      item.token_address || item.token.address,
      item.token_id || item.id,
      item.status
    );
  }

  function isAnXPass() {
    return (
      item?.collection?.address ===
        "0x6cc712a73cb45d212f594ef942385ef90141dac0" ||
      item?.collection?.address === "0xcd05E79857c1c0Eb1EeAf1573a12C25D411E0757"
    );
  }

  function isAQorb() {
    if (
      item?.collection?.address ===
        "0x2d59c7b8d253b826cdba9389be397a68af88cf08" ||
      item?.collection?.address === "0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D"
    ) {
      if (
        item.metadata.name === "One Tap Operative Mystery Box" ||
        item.metadata.name === "One Qorbi Burn"
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  return (
    <StyledAssetCard
      key={index}
      isSelected={selected.includes(index)}
      onClick={(e) => onAssetSelect(e)}
    >
      <div className="radial-blur"></div>
      <div className="inner-radial-blur"></div>
      {isAnXPass() &&
        (showActivatePassButton() ? (
          <div
            onClick={() => activateQorX(item.token_id || item.id)}
            className="active-pass"
          >
            Activate
          </div>
        ) : (
          (zkevmPass.includes(item.token_id || item.id) ||
            imxPass.includes(item.token_id || item.id)) && (
            <div onClick={onPledgingTap} className="active-pass">
              Pledging
            </div>
          )
        ))}

      {isAQorb() && (
        <div
          onClick={() => {
            onQorbPledgingTap(item.token_id || item.token);
          }}
          className="active-pass"
        >
          Pledging
        </div>
      )}
      <img
        className="asset-image"
        src={getImageSource()}
        alt={item?.name || item?.metadata?.name}
      />
      <div className="owner-username-outer-container" onClick={onAssetCardTap}>
        <div className="owner-username-inner-container">
          {item.name ||
            (item?.metadata?.name === "Qorb"
              ? item.extra?.attributes[0]?.value
              : item.metadata?.name)}{" "}
          {activeTab === "Polygon" && `#${item.id}`}
        </div>
      </div>
    </StyledAssetCard>
  );
}

export default AssetCard;
