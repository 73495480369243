import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import WalletTile from "../WalletTile";
import { Context } from "../../context/WalletContext"
import { Toast } from "../../utils/Global";
import detectEthereumProvider from "@metamask/detect-provider";
import CloseIcon from "../../assets/svg/CloseIcon";
import ChangeWalletModal from "./ChangeWalletModal";
const qorbiServices = require("../../context/QorbiService");


const StyledWalletManagerModal = styled.dialog`
    width: 695px;
    height: 555px;
    background: #1C1C1C;
    border-radius: 32px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;     
    padding: 24px;
    border-width: 0px;
    
    &::backdrop {        
        background-color: #00000090;
    }

    &[open]{
        display: flex;
    }
    
    #title{
        font-family: Exo 2;
        font-weight: 400;
        font-size: 30px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #FFFFFF;
    }

    #subtitle{
        font-family: Exo 2;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #A6A6A6;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    #wallet-list::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    #wallet-list {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    #wallet-list{
        width: 100%;
        height: 320px !important;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center; 
        margin-top: 20px;
        margin-bottom: 20px;
        gap: 50px;
        overflow-y: scroll;
        padding: 0px 0px 20px 0px;
    }

    #add-wallet{
        width: 100%;
        height: 50px;
        border-radius: 14px;
        padding: 16px;
        background: linear-gradient(#38EF7D, #11998E);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        cursor: pointer;
    }

    #modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0px 0px 16px 0px;
    }

    #close-button{
      cursor: pointer;
    }
`;

const WalletManagerModal = forwardRef(function WalletManagerModal({ }, ref) {
  const modalRef = useRef();
  const warningModalRef = useRef();
  const walletContext = useContext(Context);

  useImperativeHandle(ref, () => {
    return {
      open: open,
      close: close,
    };
  });

  function open() {
    modalRef.current.showModal();
  }

  function close() {
    modalRef.current.close();
  }

  const addMetamaskWallet = async () => {
    try {
      const providerMetamask = await detectEthereumProvider();
      if (providerMetamask) {
        try {
          await window.ethereum.request({
            method: "wallet_revokePermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          });
        } catch (error) {
          console.error("Error revoking wallet permissions:", error);
        }
        const accounts = await providerMetamask.request({
          method: "eth_requestAccounts",
        });
        const address = accounts[0];
        const nonce = await qorbiServices.generateNonce();
        const msg = `0x${Buffer.from(nonce, "utf8").toString("hex")}`;

        const signature = await providerMetamask.request({
          method: "personal_sign",
          params: [msg, address],
        });
        const userData = await qorbiServices.verifySignatureUpdateWallet(
          address,
          nonce,
          signature
        );
        var payload = walletContext.state.userWallet;
        if(JSON.stringify(userData.wallets) === JSON.stringify(payload.wallets) || !userData){
          warningModalRef.current.open();
          return;
        }
        console.log(payload)
        payload.wallets = userData.wallets;
        walletContext.updateWallets(payload);
        return { success: true, data: address };
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };

  const updateWalletRequest = async () => {
    try {
      if ("ethereum" in window) {
        await addMetamaskWallet();
      } else {
        Toast.set({
          show: true,
          desc: (
            <p>
              Metamask is not installed. <br />
              <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                Metamask
              </a>
            </p>
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (<StyledWalletManagerModal ref={modalRef}>
    <ChangeWalletModal ref={warningModalRef} title={'Something went wrong'} description={"We couldn't add your wallet, it might be linked to another account or already added to this account"}></ChangeWalletModal>
    <div id="modal-header">
      <p id="title">Manage Wallets</p>
      <div id="close-button" onClick={close}>
        <CloseIcon height={36} width={36}></CloseIcon>
      </div>
    </div>
    <p id="subtitle">Add one or more wallets to showcase all your Items in one place.</p>
    <div id="wallet-list">
      {
        walletContext.state?.userWallet?.wallets?.metamask.map((wallet) =>
          <WalletTile
            isMintingWallet={wallet === walletContext?.state?.userWallet?.mintingWallet}
            isQorbiWallet={wallet === walletContext?.state?.userWallet?.qorbiWallet}
            address={wallet}
            isMetamask={true}
          >
          </WalletTile>)

      }
      {
        walletContext?.state?.userWallet?.wallets?.passport_address &&
        <WalletTile
          isMintingWallet={walletContext?.state?.userWallet?.wallets.passport_address === walletContext?.state?.userWallet?.mintingWallet}
          isQorbiWallet={walletContext?.state?.userWallet?.wallets.passport_address === walletContext?.state?.userWallet?.qorbiWallet}
          address={walletContext?.state?.userWallet?.wallets?.passport_address}
          isMetamask={false}
        >
        </WalletTile>
      }
    </div>
    <div id="add-wallet" onClick={updateWalletRequest}>Add New Wallet</div>
  </StyledWalletManagerModal>);
});

export default WalletManagerModal;