import { useState } from "react";
import styled from "styled-components";

const StyledQorbSelector = styled.div`
    width: 150px;
    height: 30px;    
    border-radius: 16px;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: #323232;
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    color: #a6a6a6;
    padding: 0px 10px 0px 10px;

    .qorbs-list-container {
        display: ${({isOpen})=>isOpen ? 'flex' : 'none'};
        flex-direction: column;
        justify-content: space-around;
        width: 160px;
        background: #737373;
        border-radius: 10px;
        position: absolute;
        margin-top: 95px;
        backdrop-filter: blur(2px);
    }

    .qorb-option-container:hover {
        background-color: #323232;
        border-radius: 10px;
    }

    .qorb-option-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
        color: #a6a6a6;
        transition: 0.7s;
        border-radius: 10px;

        p {
            color: #FFFFFF;        
            font-size: 16px;
            font-weight: 500;
            margin: 0px;            
        }
    }
`;

function QorbSelector({qorbs, onQorbSelected, selectedQorb}){
    const [isOpen, setIsOpen] = useState(false);

    function toggleVisibility(){
        setIsOpen(!isOpen);
    }

    return (
        <StyledQorbSelector isOpen={isOpen} onClick={toggleVisibility} className="qorbi-selector-container">
            {selectedQorb}
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg>
            {
                <div className="qorbs-list-container">
                    {qorbs.map((qorb, index)=>(
                        <div className="qorb-option-container" key={`${index}_${qorb.metadata.name}`} onClick={()=>onQorbSelected(qorb.token_id || qorb?.id)}>
                            <p>
                                {qorb.metadata.name}
                            </p>
                        </div>
                    ))}
                </div>
            }
        </StyledQorbSelector>
    );
}

export default QorbSelector;