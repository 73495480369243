import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../context/WalletContext";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";
import InputWallet from "../components/InputWallet";
import styled from "styled-components";
import GradientOutlinedButton from "../components/GradientOutlinedButton";
import GreenIsotype from "../assets/img/Green_Isotype.png";

const qorbiService = require("../context/QorbiService");

const StyledQorXPass = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 80px;

  @media (width <= 900px) {
    width: 100% !important;
    flex-direction: column;
    margin-top: 20%;
    padding: 15px;
    align-items: center !important;
  }
`;

const StyledImageAndDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 35px;

  .current-multiplier-container {
    margin-top: 16px;
    color: #a6a6a6;
    font-weight: 400;
    font-size: 28px;
    line-height: 23.92px;
  }

  @media (width <= 900px) {
    width: 100% !important;
    margin-right: 0px !important;

    .current-multiplier-container {
      font-size: 22px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const StyledAssetImage = styled.div`
  height: 350px;
  width: 600px;
  border-radius: 40px;
  background: linear-gradient(0.25turn, #ffffff00, #38ef7d, #ffffff00);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    object-fit: cover;
    height: 348px;
    width: 598px;
    border-radius: 40px;
  }

  @media (width <= 900px) {
    width: 80vw;
    height: 200px;

    & img {
      width: 80vw;
      height: 198px;
    }
  }
`;

const StyledPledgesDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-family: "exo", sans-serif;

  .items-count {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23.92px;
    color: #a6a6a6;
  }

  .qorb-earning-container {
    width: 600px;
    height: 120px;
    border-radius: 32px;
    background: #ffffff0d;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 25px;

    .qorbi-earnings-title {
      color: #ffffff;
      font-size: 22px;
      line-height: 26.4px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .qorbi-earnings-quantity {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      width: 100px;
    }
  }

  .playing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    border-radius: 14px;
    border-width: 1px;
    border-color: #38ef7d;
    border-style: solid;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
  }

  .pool-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    background: transparent;
    border-radius: 14px;
    border-width: 2px;
    border-color: #ffd277;
    border-style: solid;
    color: #ffd277;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
  }

  .withdrwaw-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    background: linear-gradient(#38ef7d, #11998e);
    border-radius: 14px;
    border-width: 0px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
  }

  .history-pledging-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    height: 45px;
    background: transparent;
    border-radius: 14px;
    border-width: 1px;
    border-color: #38ef7d;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    margin-bottom: 24px;
  }

  @media (width <= 900px) {
    width: 100% !important;
    margin-top: 14px;

    .qorb-earning-container {
      width: 100%;
      border-radius: 20px;
      height: 80px;
      padding: 10px 24px 10px 24px;
      margin-bottom: 5px;
    }

    .withdrwaw-button {
      width: 80vw;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .qorbi-earnings-quantity {
      font-size: 20px !important;
    }
  }
`;

const StyledMultiplierSection = styled.div`
  flex: 2;
  justify-content: space-between;
  align-items: center;

  .multiplier-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26.4px;
    color: #ffffff;
    margin-bottom: 24px;
  }

  .multiplier-items-container {
    display: flex;
    width: 100%;
    border-radius: 32px;
    padding: 24px;
    background: #1c1c1c;
    border-width: 1px;
    border-color: #ffffff1a;
    border-style: solid;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .multiplier-list {
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .multiplier-item {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0px 44px 0px 16px;
  }

  .multiplier-name {
    font-size: 18px;
    font-weight: 300;
    line-height: 21.6px;
    color: #ffffff;
  }

  .multiplier-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #38ef7d;
  }
`;

const StyledLoadingComponent = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  p {
    color: #ffffff;
    margin: 0px;
  }
`;

const QorX = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get("source");
  const { state } = useContext(Context);
  const [qorXTransactions, setQorXTransactions] = useState(null);
  const [walletInput, setWalletInput] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const ownerAddress = state.user.data;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      const data = await qorbiService.getActivePass();
      console.log(data);
      const passSource = source === "ZkEvm" ? "zkevm" : "immutablex";
      const ownerElement = data.qorXPass.find(
        (element) =>
          element.wallet.toLowerCase() === ownerAddress.toLowerCase() &&
          element.chain === passSource
      );
      if (ownerElement) {
        console.log(state.user.token);
        const qorxData = await qorbiService.getQorXPassDetails(ownerElement.qorXNFTID, passSource);
        setQorXTransactions(qorxData);
      } else {
        navigate("/my-assets");
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching QorX data:", error);
    }
  };

  const handleWithdraw = async (address) => {
    if (qorXTransactions.stakeAsset.stake.validToWithdraw) {
      setWalletInput(false);
      const passSource = source === "ZkEvm" ? "zkevm" : "immutablex";
      const earnedString = `${qorXTransactions?.stakeAsset?.stake?.earned}`;
      setShowPopup({
        title: "Starting...",
        content: "Withdrawing QORBI from QORX Pass",
      });
      await qorbiService.withdrawQorx(
        earnedString,
        address,
        qorXTransactions?.stakeAsset?.asset?.token_id,
        passSource
      );
      window.location.reload();
    } else {
      setShowPopup({
        title: "Starting...",
        content: "Wait, the pass has not staked the 6 months, try again later",
      });
    }
  };

  function navigateToHistory() {
    navigate("/pledging-history");
  }

  useEffect(() => {
    async function loadData() {
      try {
        if (ownerAddress && qorXTransactions === null) {
          await fetchData();
        }
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qorXTransactions]);

  const renderComputerView = () => {
    return (
      <StyledQorXPass>
        {walletInput && <InputWallet onSuccess={handleWithdraw} />}
        {showPopup && (
          <Popup
            onClose={() => setShowPopup(false)}
            title={showPopup.title}
            content={
              <>
                <p>{showPopup.content}</p>
              </>
            }
          />
        )}
        {isLoading ? (
          <StyledLoadingComponent>
            <img alt="Green Isotype" src={GreenIsotype}></img>
            <p>Loading QorX Pass...</p>
          </StyledLoadingComponent>
        ) : error ? (
          <StyledLoadingComponent>
            <img alt="Green Isotype" src={GreenIsotype}></img>
            <p>Something Went Wrong</p>
            <p>While Loading Your Asset</p>
          </StyledLoadingComponent>
        ) : (
          qorXTransactions &&
          !isLoading && (
            <>
              <StyledImageAndDetailsContainer>
                <StyledAssetImage>
                  <img
                    src={
                      qorXTransactions?.stakeAsset?.asset?.image_url ||
                      qorXTransactions?.stakeAsset?.asset?.image
                    }
                    alt="QorX"
                  />
                </StyledAssetImage>
                <StyledPledgesDetails>
                  <GradientOutlinedButton
                    text={`${qorXTransactions?.stakeAsset?.asset?.name} #${
                      qorXTransactions?.stakeAsset?.asset?.metadata
                        ?.serial_number ||
                      qorXTransactions?.stakeAsset?.asset?.token_id
                    }`}
                    onTap={function () {}}
                  ></GradientOutlinedButton>
                  <div className="current-multiplier-container">
                    Current Multiplier: X{" "}
                    {qorXTransactions?.multiplierData?.multiplier}
                  </div>
                  <StyledPledgesDetails>
                    <div className="qorb-earning-container">
                      <div className="qorbi-earnings-title">Qorbi Earned:</div>
                      <div className="qorbi-earnings-quantity">
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.6953 13.6675C16.5481 12.4474 17.0504 11.0165 17.1472 9.53061C17.2441 8.04467 16.932 6.56059 16.2448 5.23992C15.5575 3.91924 14.5216 2.81258 13.2497 2.04037C11.9778 1.26817 10.5186 0.860024 9.03108 0.860355C8.66332 0.860006 8.29598 0.884966 7.93164 0.935058V2.70669C8.3488 2.62195 8.77333 2.5788 9.19899 2.57789C10.5044 2.57682 11.7787 2.97734 12.8492 3.72523C13.9197 4.47312 14.7348 5.53225 15.184 6.75922C15.6333 7.98619 15.6951 9.32173 15.361 10.585C15.0269 11.8483 14.3131 12.9783 13.3163 13.822L14.2253 14.7436L14.5 15.0224L16.3791 16.9299H19.1023L15.6927 13.6655L15.6953 13.6675Z"
                            fill="white"
                          />
                          <path
                            d="M12.5828 14.3629C11.2087 15.237 9.55246 15.5512 7.95422 15.2409C6.35597 14.9306 4.93711 14.0195 3.9891 12.6946C3.04108 11.3696 2.63591 9.73162 2.8568 8.11698C3.07769 6.50233 3.90788 5.03366 5.17682 4.01268L5.19741 9.02166C5.19741 10.0733 5.61472 11.0818 6.35754 11.8254C7.10036 12.569 8.10784 12.9867 9.15835 12.9867C9.6827 12.9917 10.2028 12.8923 10.6885 12.6945C11.1742 12.4967 11.6159 12.2043 11.9878 11.8343C12.3598 11.4643 12.6547 11.0241 12.8554 10.5391C13.0561 10.0542 13.1587 9.53419 13.1571 9.00928C13.1555 8.48436 13.0498 7.96498 12.8462 7.48126C12.6426 6.99753 12.3451 6.55908 11.9709 6.19134C11.5967 5.8236 11.1533 5.53387 10.6664 5.33897C10.1796 5.14407 9.65886 5.04787 9.13455 5.05595C8.71486 5.05785 8.29815 5.12654 7.90001 5.25945V7.20753C8.29579 6.90956 8.7803 6.75373 9.27539 6.7652C9.77047 6.77667 10.2473 6.95476 10.6289 7.27074C11.0104 7.58672 11.2746 8.02217 11.3786 8.50684C11.4827 8.99151 11.4206 9.49714 11.2024 9.94216C10.9842 10.3872 10.6226 10.7456 10.1759 10.9597C9.72924 11.1738 9.22355 11.231 8.74043 11.1221C8.2573 11.0132 7.82491 10.7446 7.513 10.3595C7.20109 9.97447 7.02785 9.49546 7.02123 8.99977L7.00643 5.69221L7.02123 2.95717V1.11084C5.10116 1.60098 3.42593 2.77603 2.31018 4.41526C1.19444 6.0545 0.71496 8.04513 0.961805 10.0133C1.20865 11.9814 2.16484 13.7916 3.65076 15.1038C5.13669 16.4161 7.05011 17.1401 9.03162 17.1399C9.12361 17.1399 9.21561 17.1399 9.30567 17.1347V17.1399L15.1953 16.9306L13.78 15.6084L12.5821 14.3636L12.5828 14.3629Z"
                            fill="white"
                          />
                        </svg>
                        {Math.floor(
                          qorXTransactions?.stakeAsset?.stake?.earned * 10000
                        ) / 10000}
                      </div>
                    </div>
                  </StyledPledgesDetails>
                  <button
                    className="withdrwaw-button"
                    onClick={() => setWalletInput(true)}
                  >
                    Withdraw Earning
                  </button>
                  <button
                    className="history-pledging-button"
                    onClick={() => navigateToHistory()}
                  >
                    History Pledging
                  </button>
                </StyledPledgesDetails>
              </StyledImageAndDetailsContainer>
              <StyledMultiplierSection>
                <div className="multiplier-items-container">
                  <p className="multiplier-title">Multiplier Items</p>
                  <div className="multiplier-list">
                    {qorXTransactions?.multiplierData?.itemsMultipliers
                      ?.length > 0 ? (
                      qorXTransactions?.multiplierData?.itemsMultipliers.map(
                        (row) => (
                          <div className="multiplier-item" key={row.id}>
                            <div className="multiplier-name">{row.name}</div>
                            <div className="multiplier-value">{`x ${row.multiplier}`}</div>
                          </div>
                        )
                      )
                    ) : (
                      <div>No Items</div>
                    )}
                  </div>
                </div>
              </StyledMultiplierSection>
            </>
          )
        )}
      </StyledQorXPass>
    );
  };

  return renderComputerView();
};

export default QorX;
