import React, {useState} from 'react';

const Checkbox = ({name,onClick,enable=false}) => {
    const [active,setActive]=useState(enable)
    const click=()=>{
        setActive(!active)
        onClick({name:name,value:!active})
    }
    return (
        <div className='full'>
            <div className='checkbox-container' onClick={()=>click()}>
                <div className={`checkbox ${active ? 'active' :''}`}>{active ? <i className='fa fa-check'/>:null}</div>
                <span className={`${active ? 'active' :''}`}>{name}</span>
            </div>
        </div>
    );
};

export default Checkbox;