/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../context/WalletContext";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers';
import { marketItems } from "../utils/Global";
import { Button } from "../components/Button";
import LoaderGlass from "../assets/svg/LoaderGlass";
import Popup from "../components/Popup";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
const qorbiService = require('../context/QorbiService');

const SingleMarket = () => {
  const { qorbiUserData } = useSphereOneProvider();
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [itemToShow, setItemToShow] = useState(null);
  const [showLoader, setShowLoader] = useState([]);
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const { state } = useContext(Context);

  const readCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name && id !== "21") {
        return cookieValue;
      }
    }

    return null;
  };

  const buyItem = () => {
    const username = "QorbiUser"
    let referralID = readCookie("rewardful.referral")?.split("%22")[3]
    if (referralID === undefined) {
      referralID = ""
    }
    try {
      if (state?.userWallet?.string === undefined) {
        throw new Error("No Wallet");
      }
      window.open(
        itemToShow.stripe + `${state.userWallet.string}${username}${referralID}`,
        "_blank",
        "data-rewardful"
      )
    } catch (error) {
      localStorage.setItem('unauthorizedPath', `/single/${id}`);
      setShowConnectMessage(true)
    }
  }

  const purchaseUsingQorbi = async () => {

    // const tokenAddress = '0xeab326d39cb85a3d6b1590e0342f9851aec6151d' //Amoy token
    const tokenAddress = '0x07278800fFBbB715C826E9D780FFfE7ec7001127' //Qorbi token
    // const recipient = '0xf798562a8264D53d4256780f6888DACC7b34A787' //Admin Wallet
    const recipient = '0x5f3f479D94e7f3559bA451f9E79aF3DB6E8b43A0' //Admin Wallet
    // const amoyTestChainId = '0x13882';
    const polygonChainId = '0x89';

    try {
      const providerMetamask = await detectEthereumProvider();
      if (providerMetamask) {
        const accounts = await providerMetamask.request({
          method: "eth_requestAccounts",
        })
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        if (network.chainId !== parseInt(polygonChainId, 16)) {
          setShowLoader(["Incorrect Network", "Switch to Polygon"]);
          alert("Please switch to the Polygon network.");
          return;
        }
        const signer = provider.getSigner();
        const erc20Abi = [
          "function balanceOf(address owner) view returns (uint256)",
          "function transfer(address to, uint256 amount) returns (bool)"
        ];
        const contract = new ethers.Contract(tokenAddress, erc20Abi, signer);
        const balance = await contract.balanceOf(accounts[0]);
        const userId = qorbiUserData?._id || 'no-session'
        const assetData = await qorbiService.getQorbiPrice(itemToShow.name, userId);
        if (balance.lt(ethers.utils.parseUnits(assetData.price, 18))) {
          setShowLoader(["Insufficient Funds", "Please top up your wallet"]);
          alert("You do not have enough Qorbi tokens.");
          return;
        }
        const formattedPrice = parseFloat(assetData.price).toFixed(2);
        setShowPopup({ price: formattedPrice });
        setShowLoader(["Sending Funds", "Sending Funds"]);
        const tx = await contract.transfer(recipient, ethers.utils.parseUnits(assetData.price, 18));
        await tx.wait();
        setShowLoader(["Minting Assets", " Wait a second..."]);
        const minted = await qorbiService.mintQorbiMarket(accounts[0], userId);
        if (minted.status === 'success') {
          window.location.href = '/my-assets';
        }
      }

    } catch (error) {
      setShowLoader(["Minting Failed", "Contact Support"]);
      console.log(error.message);
    }
  }

  useEffect(() => {
    localStorage.removeItem('unauthorizedPath');
    const fetchItemInfo = () => {
      const item = marketItems.find(item => item.id.toString() === id);
      try {
        if (item !== undefined && item.url !== 'ember') {
          setItemToShow({
            name: item.name,
            image: item.img,
            amount: item.price,
            stripe: item.url,
            coinbase: item.coinbase,
            qorbi: item?.qorbi,
            desc: item.desc,
          });
        } else {
          setItemToShow({
            name: marketItems[2].name,
            image: marketItems[2].img,
            amount: marketItems[2].price,
            stripe: marketItems[2].url,
            coinbase: marketItems[2].coinbase,
            desc: marketItems[2].desc,
          });
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchItemInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="darkgreen-bg">
      {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
      <div className="section">
        {showLoader.length > 0 &&
          <div className="loader-center">
            <div style={{ fontSize: '24px', marginBottom: '10px' }}>
              {showLoader[0]}
            </div>
            <LoaderGlass />
            <div style={{ fontSize: '24px', marginBottom: '10px' }}>
              {showLoader[1]}
            </div>
          </div>
        }
        {showPopup && (
          <Popup
            onClose={() => setShowPopup(false)}
            title={`Amount:${showPopup.price}`}
            content={
              <>
                <p>
                  The Item Will Go To The Wallet Used To Pay
                </p>
                <Button transparent label="Ok" onClick={() => setShowPopup(false)} />
              </>
            }
          />
        )}
        <div className="container">
          <div className="row single-baby">
            <div className="col-12 col-md-7">
              <div className="row media-container">
                <div className="col-md-12 col-lg-10" style={{ display: "flex", flexDirection: 'column' }}>
                  <img src={itemToShow?.image} style={{ maxHeight: "45vh", alignSelf: 'center' }} alt="Minting" />
                  <p className="attribute-title" style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: itemToShow?.desc }} />
                  <h4 className="attribute-title">
                    If the minting fails contact an admin on discord
                    To see your {itemToShow?.name} click on{" "}
                    <a
                      href="/my-assets"
                      rel="noreferrer"
                      target="_blank"
                      style={{ color: "#51e180" }}
                    >
                      My Assets
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <h2 className="baby-name">{itemToShow?.name}</h2>
              <div className="baby-price">{itemToShow?.amount}$</div>
              <div>
                {itemToShow?.stripe &&
                  <button className="greenBtn" onClick={() => buyItem()}>
                    Mint Using Credit Card
                  </button>
                }
              </div>
              {(itemToShow?.qorbi || itemToShow?.coinbase) &&
                <div className="tab-container">
                  <div className="tab-body">
                    <h2 className="baby-name" style={{ fontSize: "22px", marginTop: "30px" }}>Or...</h2>
                  </div>
                </div>
              }
              {itemToShow?.qorbi &&
                <div>
                  <button className="greenBtn" onClick={purchaseUsingQorbi}>
                    Mint Using $QORBI (35% Off)
                  </button>
                </div>
              }
              {itemToShow?.coinbase &&
                <div>
                  <div className="tab-container">
                  </div>
                  <button className="greenBtn" onClick={() => window.open(itemToShow.coinbase)}>
                    Mint Using Crypto
                  </button>
                </div>
              }
              {(itemToShow?.qorbi || itemToShow?.coinbase) &&
                <p style={{ fontSize: 16, color: "red" }}>Important: If you use this methods, the wallet you use to pay from is where we will send the {itemToShow?.name}. If you are not sure about the wallet, please open a support ticket in discord.</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMarket;
