import styled from "styled-components";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WalletActionsButton from "./WalletActionsButton";
import PassportLogo from "../assets/svg/PassportLogo";
import ImmutableLogo from "../assets/svg/ImmutableLogo";

const StyledWalletTile = styled.div`    
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .radio-button{
        width: 20px;
        height: 20px;
        border-color: #A6A6A6AA;
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;
    }
    
    .wallet-icon{
        margin-right: 20px;
    }

    .spacer{
        height: 60px;
        display: flex;
        flex: 2;
    }

    .wallet-info{
        display: flex;
        flex-direction: column;        
        justify-content: space-between;
        align-items: start;        
        margin-right: 20px;
    }

    .wallet-address{
        color: #FFFFFF;
    }

    .wallet-provider-connection{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wallet-provider-name{
        margin: 0px 10px 0px 0px;
        color: #38EF7D;
        font-family: Exo 2;
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;                
    }
`;

export default function WalletTile({isMetamask, address, isMintingWallet, isQorbiWallet}){

    function shortenAddress(address) {
        if (address.length <= 22) return address; // Ensure it's long enough to shorten
        return `${address.slice(0, 18)}...${address.slice(-4)}`;
    }

    return (<StyledWalletTile>
        <div className="wallet-icon">
            {
                isMetamask ?
                    <MetamaskLogo width={60} height={60}/>
                :
                    <ImmutableLogo width={60} height={60}></ImmutableLogo>
            }
        </div>
        <div className="wallet-info">
                <div onClick={function () {
                  return navigator.clipboard.writeText(address);
                }}>
            <p className="wallet-address">{shortenAddress(address)}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 15C9 12.1716 9 10.7574 9.87868 9.87868C10.7574 9 12.1716 9 15 9H16C18.8284 9 20.2426 9 21.1213 9.87868C22 10.7574 22 12.1716 22 15V16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15C12.1716 22 10.7574 22 9.87868 21.1213C9 20.2426 9 18.8284 9 16V15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.9999 9C16.9975 6.04291 16.9528 4.51121 16.092 3.46243C15.9258 3.25989 15.7401 3.07418 15.5376 2.90796C14.4312 2 12.7875 2 9.5 2C6.21252 2 4.56878 2 3.46243 2.90796C3.25989 3.07417 3.07418 3.25989 2.90796 3.46243C2 4.56878 2 6.21252 2 9.5C2 12.7875 2 14.4312 2.90796 15.5376C3.07417 15.7401 3.25989 15.9258 3.46243 16.092C4.51121 16.9528 6.04291 16.9975 9 16.9999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </p>
                </div> 
            
            <div className="wallet-provider-connection">
                {
                    isMintingWallet &&
                        <p className="wallet-provider-name">Minting Wallet</p> 
                }
                {               
                    isQorbiWallet &&
                        <p className="wallet-provider-name">QORBI Wallet</p>                
                }
            </div>
        </div>        
        <div className="spacer"></div>
        <WalletActionsButton walletId={address}></WalletActionsButton>
    </StyledWalletTile>);
}