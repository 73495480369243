/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import Success from '../assets/svg/Success'
import Failed from '../assets/svg/Failed'
import axios from 'axios';
import { Context } from "../context/WalletContext";
import { ImmutableXClient } from "@imtbl/imx-sdk";
import OTPInput from "../components/OTP";
import { useSphereOneProvider } from "../utils/SphereOneHooks";

const qorbiServices = require('../context/QorbiService');

const QorbabyConfirmation = () => {
    const { state } = useContext(Context);
    const [showOTP, setShowOTP] = useState(false);
    const [allowClick, setAllowClick] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);

    const { qorbiUserData } = useSphereOneProvider();
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '0 20px',
    };
    const cardStyle = {
        marginTop: "50px",
        backgroundColor: '#1A1919',
        color: '#000000',
        border: '3px #fff',
        borderRadius: '12%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        width: '90%',
        maxWidth: '600px',
    };
    const titleStyle = {
        fontSize: '36px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#fff',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    };
    const imageStyle = {
        width: '75%',
        height: 'auto',
        borderRadius: '8px',
        marginTop: '20px',
    };
    const textStyle = {
        color: '#636363',
        marginTop: '20px',
    };

    const [currentQorb, setCurrentQorb] = useState({})

    const relocate = () => {
        // window.location.href = "/my-assets";
    }

    const handleOtpSubmit = async (otpCode) => {
        setShowOTP(false)
        try {
            setAllowClick(false)
            if (urlParams.get('source') === 'ZkEvm') {
                alert('Minting Qorb')
                await axios.post(
                    `https://api.qorbiworld.com/zkevm/mint-qorb`,
                    {
                        badgeTokenId: currentQorb.badge,
                        name: currentQorb.name,
                        qorbMongooseId: currentQorb.id,
                        otp: otpCode,
                    },
                    {
                        timeout: 120000
                    }
                );
            } else {
                await axios.post(`https://api.qorbiworld.com/v1/nft/create-order-qor`, {
                    walletAddress: currentQorb.wallet,
                    name: currentQorb.name,
                    qorbId: currentQorb.id,
                    tokenBadgeId: currentQorb.badge,
                    email: qorbiUserData.username,
                    otp: otpCode,
                });
            }
            alert('Qorb minted successfully!');
            setTimeout(() => {
                relocate();
            }, 5000);
        } catch (error) {
            console.error("Error creating order:", error);
            alert('Error creating order');
        }
    };

    const handleCloseOtpSubmit = () => {
        setShowOTP(false)
    };

    useEffect(async () => {
        if (state?.userWallet?.string === urlParams.get('wallet') || qorbiUserData?.openLootInfo?.id === urlParams.get('wallet')) {
            const badge = urlParams.get('badge')
            setCurrentQorb({
                id: urlParams.get('id'),
                name: urlParams.get('name'),
                image: urlParams.get('image'),
                badge,
                wallet: state.userWallet.string,
            })
        } else {
            relocate()
        }
    }, [qorbiUserData, state])

    const handleConfirmClick = async () => {
        if (allowClick) {
            qorbiServices.requestOtp()
            setShowOTP(true)
        }
    };

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                <img
                    src={currentQorb?.image}
                    alt="Selected Qorb"
                    style={imageStyle}
                />
                <h2 style={titleStyle}>Qorb: {currentQorb?.name}</h2>
                <p style={textStyle}>
                    <strong style={{ color: "#fff" }}>Wallet:</strong> {currentQorb?.wallet?.substring(0, 6)}...{currentQorb?.wallet?.substring(currentQorb?.wallet?.length - 5)}
                </p>
                <p style={textStyle}>
                    This is the <a style={{ color: "white" }}>QORB</a> you selected to mint from your <a style={{ color: "white" }}>badge #{currentQorb.badge}</a>. This selection will be <a style={{ color: "white" }}>permanent</a>. To confirm the mint, please click the first button. To select a different a <a style={{ color: "white" }}>QORB</a> hit the second button.
                </p>
                <div>
                    <Success onClick={handleConfirmClick} disabled={allowClick}></Success>
                    <Failed onClick={window.close} style={{ marginBottom: '20px' }}></Failed>
                </div>
                {showOTP && <OTPInput onSubmit={handleOtpSubmit} onCancel={handleCloseOtpSubmit} />}
            </div>
        </div >
    );
};

export default QorbabyConfirmation;
