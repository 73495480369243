import React, { useState, useRef, useEffect } from 'react';
import ErrorShowing from "../components/ErrorShowing";
import LoadingTextLootbox from '../assets/svg/LoadingTextLootbox';
import lootbox from '../assets/img/Treasure_Chest_Shine.png';
import ember70000 from '../assets/img/fuckOpenLoot/ember_package_70000.png';
import closedChest from '../assets/sounds/closedChest.mp3';

const qorbiServices = require('../context/QorbiService');

const OpenMysteryBox = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentBox = urlParams.get('id');
    const lootboxRef = useRef(null);
    const videoRef = useRef(null);

    const [showVideo, setShowVideo] = useState(false);
    const [showLootbox, setShowLootbox] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [response, setResponse] = useState(false);
    const [skinsInfo, setSkinsInfo] = useState([]);
    const [loadingLootbox, setLoadingLootbox] = useState(false);

    const handleLootboxClick = () => {
        if (response === true) {
            setShowLootbox(false);
            setShowVideo(true);
        } else {
            console.log(skinsInfo);
            new Audio(closedChest).play();
        }
    };

    const handleVideoLoad = () => {
        if (lootboxRef.current) {
            lootboxRef.current.style.transform = 'scale(1)';
        }
    };

    const fetchMetadata = async (collection, tokenId) => {
        const response = await fetch(`https://assets.qorbiworld.com/resource/${collection}/${tokenId}`);
        if (!response.ok) {
            throw new Error(`Error fetching metadata for token ${tokenId}: ${response.statusText}`);
        }
        return response.json();
    };

    const fetchAllMetadata = async (data) => {
        const collections = {
            pet: data.petTokenId,
            skin: data.skinTokenId,
            badge: data.qorbadgeTokenId,
        };
        const metadataPromises = Object.entries(collections).map(async ([collection, tokenId]) => {
            if (tokenId) {
                return fetchMetadata(collection, tokenId);
            } else {
                if (collection === 'skin') {
                    console.log(data);
                    
                    return {
                        image_url: ember70000,
                        name: data.ember,
                    };
                }
                return null;
            }
        });
        try {
            const metadataResults = await Promise.all(metadataPromises);
            setSkinsInfo(metadataResults.filter(result => result !== null));
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };

    const OpenMysteryBox = async () => {
        setLoadingLootbox(true);
        try {
            const data = await qorbiServices.openMysteryBox(currentBox);
            // const { followId } = { followId: '29e38be4-f994-4b99-9cd1-56441d1c53f3' }
            let status;
            do {
                const boxResponse = await qorbiServices.trackMysteryBox(data.followId);
                // const boxResponse = await qorbiServices.trackMysteryBox(followId);
                status = boxResponse?.status;
                if (status !== 'success' && status !== 'failed') {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                } else if (status === 'success') {
                    fetchAllMetadata(boxResponse);
                    setLoadingLootbox(false);
                    setResponse(true);
                    setErrorMsg("Items Found, click the lootbox to open");
                } else if (status === 'failed') {
                    setLoadingLootbox(false);
                    setErrorMsg("Lootbox opening went wrong, contact us");
                    setTimeout(() => setErrorMsg(null), 4000);
                }
            } while (status !== 'success' && status !== 'failed');

        } catch (e) {
            console.error("Lootbox opening went wrong: ", e);
            setLoadingLootbox(false);
            setErrorMsg("Lootbox opening went wrong, contact us");
            setTimeout(() => setErrorMsg(null), 4000);
        }
    };

    useEffect(() => {
        // if (true) {

        // } else {
        //     navigate('/my-assets');
        // }
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ color: '#fff', textAlign: 'center', marginTop: '75px' }}>
            <div
                style={{ margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', }}
            >
                {loadingLootbox && <LoadingTextLootbox></LoadingTextLootbox>}
                {errorMsg && <ErrorShowing errorText={errorMsg} backColor={"#BB256E"} />}
                {showLootbox && !showVideo && (
                    <div>
                        <img
                            ref={lootboxRef}
                            src={lootbox}
                            alt="Lootbox"
                            style={{
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                transform: 'scale(1)',
                                width: '100%',
                                maxWidth: '800px',
                            }}
                            onClick={() => {
                                handleLootboxClick();
                                if (lootboxRef.current) {
                                    lootboxRef.current.style.transform = 'scale(1.1)';
                                    setTimeout(() => {
                                        lootboxRef.current.style.transform = 'scale(1)';
                                    }, 300);
                                }
                            }}
                        />
                        <h2>MYSTERY BOX</h2>
                        <p style={{ margin: '0 20px' }}>Open it and get fantastic in-game items. Can take up to 2 min to find the perfect match for you</p>
                        <button
                            style={{
                                marginTop: '20px',
                                padding: '15px 30px',
                                fontSize: '18px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={OpenMysteryBox}
                        >
                            Open Mystery Box
                        </button>
                    </div>
                )}
                {showVideo && (
                    <div>
                        <video
                            id="video"
                            autoPlay
                            controls={false}
                            onEnded={() => setShowVideo(false)}
                            ref={videoRef}
                            style={{ width: '100vh', maxHeight: '700px' }}
                            onLoadedData={handleVideoLoad}
                        >
                            <source src={'https://digital.qorbiworld.com/ChestPR02.mp4'} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                {!showLootbox && !showVideo && (
                    <div className="results-mystery-container">
                        {skinsInfo.map((skin, index) => (
                            <div key={index} className="result-mystery-item">
                                <img
                                    src={skin.image_url || skin.image}
                                    alt={skin.name}
                                    className="result-mystery-image"
                                />
                                <h2 className="result-mystery-title">{skin.name}</h2>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default OpenMysteryBox;
