import { passport, config } from "@imtbl/sdk";

const isLocalhost = window.location.hostname === 'localhost';

export const passportInstance = new passport.Passport({
    baseConfig: {
        environment: isLocalhost ? config.Environment.SANDBOX : config.Environment.PRODUCTION,
    },
    clientId: isLocalhost ? '0zVBU9Biei3EFItHcLafzyfHxvduJ8LQ' : 'pGvnlPTHoBui5e8g36pg6VaaVvzip1fo',
    redirectUri: isLocalhost ? 'http://localhost:3000/profile' : 'https://qorbiworld.com/profile',
    logoutRedirectUri: isLocalhost ? 'http://localhost:3000/profile' : 'https://qorbiworld.com/profile',
    audience: 'platform_api',
    scope: 'openid offline_access email transact',
});
