/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { Banner } from "../components/Banner";
import { Button } from "../components/Button";
import { Context } from "../context/WalletContext";

class Games extends Component {
  static contextType = Context;
  render() {
    return (
      <div className="darkbg">
        <Banner
          //imgURL="https://imagedelivery.net/iqzXzl6cKZZq6VjsnAU2MA/e9280470-8872-4017-3c93-5cb56947af00/public"
          videoURL="https://digital.qorbiworld.com/webpromo.mp4"
          title={<>One Tap Beta</>}
          //extraText={this.context.state.babiesNumber > 0 ? this.context.state.babiesNumber + ' Babies have found their Owners! PRESALE SOLD OUT' : ''}
          subtitle={
            <>
              "One Tap" is a high-octane first-person shooter game that delivers
              an immersive and competitive gaming experience. It's all about the
              thrill of the game, the strategy, and the skill. The unique twist?
              You get to choose an in-game asset with special abilities that can
              turn the tide of the battle.
            </>
          }
          buttons={
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <div className="button-container">
                <Button
                  type="link"
                  label="Loot Box"
                  link="/market"
                  transparent={true}
                />
                <div className="space" />
              </div>
              <div className="button-container">
                <button
                  className="btnTransparent customBtn"
                  onClick={() => window.open("https://playonetap.com/download", "_blank")}
                  style={{ display: "flex", alignItems: "center", width: "auto" }}
                >
                  Download Now
                  {/* <img
                    src={logoElixir}
                    alt="Download from"
                    style={{ height: "60%", marginLeft: "5px" }}
                  /> */}
                </button>
                <div className="space" />
              </div>
            </div>
          }
        />

        <div className="section meet-baby">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">Active Games</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <iframe
                  src="https://iframe.cloudflarestream.com/0f7a23edd50928f0718d02321062b385?loop=true&poster=https%3A%2F%2Fcloudflarestream.com%2F0f7a23edd50928f0718d02321062b385%2Fthumbnails%2Fthumbnail.jpg%3Ftime=1s%3D%26height%3D800"
                  style={{ height: "100%", width: "100%", minHeight: 200 }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div className="col-md-12 col-lg-6">
                <h2 className="green-title text-left">
                  One Tap - (Beta Released)
                </h2>
                <p style={{ fontSize: 18 }}>
                  "One Tap" is a first-person shooter game that focuses on
                  delivering an immersive and competitive gaming experience. The
                  unique twist of choosing an in-game asset with special
                  abilities adds a layer of strategy and depth to the gameplay.
                  The game has 5 rounds and for every round you win you will
                  earn $QORBI. First player to win 3 rounds wins the match (and
                  earns the bulk of the $QORBI). Winning isn't everything, but
                  it sure does help you earn more.
                </p>
                <div>
                  <a
                    href="https://playonetap.com/"
                    target="_blank"
                    className="greenBtn1"
                    style={{ width: 160 }}
                    rel="noreferrer"
                  >
                    {" "}
                    <span>Game Details</span>
                  </a>
                  <a
                    href="https://playonetap.com/"
                    target="_blank"
                    className="greenBtn1"
                    style={{ width: 160, marginTop: 10, marginBottom: 10 }}
                    rel="noreferrer"
                  >
                    <span>Download</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section meet-baby">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <iframe
                  src="https://customer-2bwi7pce1aev1tze.cloudflarestream.com/dae5c1ff80115f5eff0667ac8836ce1a/iframe?loop=true&poster=https%3A%2F%2Fcustomer-2bwi7pce1aev1tze.cloudflarestream.com%2Fdae5c1ff80115f5eff0667ac8836ce1a%2Fthumbnails%2Fthumbnail.jpg%3Ftime=30s%3D%26height%3D600"
                  style={{ height: "100%", width: "100%", minHeight: 400 }}
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div className="col-12 col-lg-6">
                <h2 className="green-title text-left">Roam</h2>
                <p style={{ fontSize: 18 }}>
                  Roam is an open world game where the Qorb characters can
                  interact. This world is dedicated to socializing, upgrading
                  your Qorb, purchasing pets or other in-game items, and having
                  fun with the water guns or dart guns. After the initial launch
                  of Roam, its growth and expansion will be voted on by the
                  Qorbs and Qorbi holders through the use of the Qorbi DAO.{" "}
                </p>
                <div>
                  <a
                    href="https://whitepaper.qorbiworld.com/qorbi-world/qorb-storyline"
                    target="_blank"
                    className="greenBtn1"
                    style={{ width: 160 }}
                    rel="noreferrer"
                  >
                    {" "}
                    <span>Game Details</span>
                  </a>
                  <a
                    href="https://discord.com/invite/9Rv4nwxx5Y"
                    target="_blank"
                    className="greenBtn1"
                    style={{ width: 160, marginTop: 10, marginBottom: 10 }}
                    rel="noreferrer"
                  >
                    <span>Join Community</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container more-games">
          <div className="row">
            <div className="col-12">
              <h2 className="green-title">More Games</h2>
              <p>
                Our dedication to our community starts with our games. Here is a
                sneak peak at some of the Qorbi World future games. The planning
                and preperation for these games have begun, but official
                development will begin after the release of One Tap. We may
                start the One Tap Mobile game sooner if Solana Mobile chooses us
                for their grant program.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="section meet-baby">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <iframe
                  src="https://customer-2bwi7pce1aev1tze.cloudflarestream.com/e835e5085bba436c991121fb0491f438/iframe?loop=true&poster=https%3A%2F%2Fcustomer-2bwi7pce1aev1tze.cloudflarestream.com%2Fe835e5085bba436c991121fb0491f438%2Fthumbnails%2Fthumbnail.jpg%3Ftime=3s%3D%26height%3D600"
                  style={{ height: "100%", width: "100%", minHeight: 400 }}
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div className="col-md-12 col-lg-6">
                <h2 className="green-title text-left">
                  One Tap Mobile - Coming Soon
                </h2>
                <p style={{ fontSize: 18 }}>
                  All the great fun and features from our FPS game One Tap on
                  mobile Andoird and iOS. One Tap Mobile is an action packed 1v1
                  first person shooter. Built on Solana for in-game rewards
                  through our win-to-earn model.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Games;
