import styled from "styled-components";

function GradientOutlinedButton({text, onTap, style}) {
    const StyledGradientOutlinedButton = styled.div`        
        display: flex;
        padding: 1px;
        justify-content: center;
        align-items: center;
        background: linear-gradient(#38ef7d70, #11998e70);
        border-radius: 14px;

        & .owner-username-inner-container {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          background-color: #1a1a1a;
          border-radius: 14px;
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
          line-height: 26.58px;
          font-family: "helvetica";
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        @media (width <= 500px) {

        } 
      `;

  return (
    <StyledGradientOutlinedButton onClick={()=>onTap()} style={style}>
      <div className="owner-username-inner-container">
        {text}
      </div>
    </StyledGradientOutlinedButton>
  );
}

export default GradientOutlinedButton;
